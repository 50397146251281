/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Office from './Office';
import User from './User';
import { useEffect } from 'react';
import { isUserInAdminRole } from '@shared/context/auth';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Admin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserInAdminRole()) {
      navigate('/');
    }
  }, []);

  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 m-2 flex flex-col h-[97%]'>
      <Title level={5} className='ml-4'>Admin</Title>
      <Tabs type='card' className='bg-white mt-4 mt-4 flex-grow overflow-hidden' items={[
        { 
          label: `Offices`,
          key: '1',
          children: <Office />
        },
        { 
          label: `Users`,
          key: '2',
          children: <div className='h-full overflow-y-auto'><User /></div>
        }
      ]}>
      </Tabs>
    </Content>
  );
};

export default Admin;
