import TemplateCard from '@components/TemplateCard';
import { ITemplate } from '@interfaces/template.interface';
import { findTemplatesRequest } from '@shared/services/template.service';
import { TemplateCategories } from '@shared/utils/enums';
import { Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';

const { Title } = Typography;

const Material = () => {
  const [fetching, setFetching] = useState(true);
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      findTemplatesRequest()
        .then((res) => {
          setFetching(false);
          setTemplates(res.response);
        })
    };

    fetchData();
  }, []); 

  const _onAdd = (template: ITemplate) => {
    setTemplates([ ...templates, template ]);
  }

  const _onDelete = (id: number) => {
    const cloned = [...templates];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setTemplates([...cloned]);
    }
  }

  const _onClicked = (id: number) => {
    const cloned = templates.map(template => template.id === id ? { ...template, clicks: template.clicks + 1 } : template );
    setTemplates([...cloned]);
  }

  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 mb-8 m-2 flex flex-col h-[97%] overflow-auto'>
      <Title level={5} className='ml-4'>Downloads</Title>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 p-4 mb-1'>
        <TemplateCard title='Buyer Templates' category={TemplateCategories.BUYER} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.BUYER).sort((a, b) => b.clicks - a.clicks)} fetching={fetching}/>
        <TemplateCard title='Seller Templates' category={TemplateCategories.SELLER} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.SELLER).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='Other Sales Templates' category={TemplateCategories.OTHER} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.OTHER).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='DRE Internal Docs and Forms' category={TemplateCategories.INTERNAL} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.INTERNAL).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='Training Videos' category={TemplateCategories.VIDEO} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.VIDEO).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='Commercial Templates' category={TemplateCategories.COMMERCIAL} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.COMMERCIAL).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='Agent Success Program' category={TemplateCategories.MARKETING} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.MARKETING).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
        <TemplateCard title='Miscellaneous' category={TemplateCategories.MISC} add={_onAdd} remove={_onDelete} update={_onClicked} files={templates.filter((template) => template.category === TemplateCategories.MISC).sort((a, b) => b.clicks - a.clicks)}  fetching={fetching}/>
      </div>
    </Content>
  );
};

export default Material;