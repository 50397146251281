import { gql } from '@apollo/client';

export const BUSINESS_QUERY = gql`
  query Business {
    business {
      id
      mlsId
      mlsName
      realtorId
      name
      business
      address
      state
      type
    }
  }
`;
