import { gql } from '@apollo/client';

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(createDocumentInput: $input) {
      id
      url
      name
      extension
      size
      modifyDate
      createdAt
    }
  }
`;