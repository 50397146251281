import { isUserInAdminRole } from '@shared/context/auth';
import { referredReportRequest } from '@shared/services/report.service';
import { exportJsonToCsv, getStateName } from '@shared/utils/functions';
import { Button, message, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FaFileCsv } from 'react-icons/fa6';
import { MdOutlineQueryStats } from 'react-icons/md';

const columns = isUserInAdminRole() ? [
  { title: <span className='text-sm'>Agent Name</span>, width: 150, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.agentname.localeCompare(b.agentname) },
  { title: <span className='text-sm'>Date Referred</span>, width: 80, dataIndex: 'hiredate', key: 'hiredate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
  { title: <span className='text-sm'>Office</span>, width: 180, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
  { title: <span className='text-sm'>State</span>, width: 100, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
  { title: <span className='text-sm'>Referred By</span>, width: 150, dataIndex: 'referrername', key: 'referrername', sorter: (a: any, b: any) => a.referrername.localeCompare(b.referrername) },
] : [
  { title: <span className='text-sm'>Agent Name</span>, width: 150, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.agentname.localeCompare(b.agentname) },
  { title: <span className='text-sm'>Date Referred</span>, width: 80, dataIndex: 'hiredate', key: 'hiredate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
  { title: <span className='text-sm'>Office</span>, width: 180, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
  { title: <span className='text-sm'>State</span>, width: 80, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
];
  
const Referred = () => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useState<any[]>([]);

  const _OnRunReport = () => {
    setFetching(true);

    referredReportRequest()
      .then((res: any) => {
        setResults(res.response);
        setFetching(false);
      });
  };

  const _OnExportReport = () => {
    if(results.length > 0) {
      const csvData = results.map(({ __typename, agentname, hiredate, ...rest }) => ({ agentname, hiredate: hiredate ? dayjs(hiredate).format('MM/DD/YYYY') : '', ...rest }));
      exportJsonToCsv(csvData, `Agent_Referred_Report_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Agent Name', 'Date Referred', 'Office', 'State', 'Referred By']);
      messageApi.open({ type: 'success', content: 'Report data exported correctly' });
    } else {
      messageApi.open({ type: 'error', content: 'There are no records to export' });
    }
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 h-[97%]'>
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4'>
          <div className='col-span-9'></div>
          <div className='col-span-3 flex items-center justify-end'>
            <Button className='rounded-xl' onClick={_OnRunReport} icon={<MdOutlineQueryStats size={20} className='mt-1' />}>Run Report</Button>
            <Button className='rounded-xl ml-2' onClick={_OnExportReport} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
          </div>
        </div>
        <div className='h-[calc(100vh-280px)] mt-[-30px]'>
          <Table
            columns={columns}
            loading={fetching}
            size='small'
            pagination={{ 
              pageSizeOptions: ['25', '50', '100'], 
              defaultPageSize: 50,
              total: results.length,
              showTotal: (total) => `${total} Results`,
            }}
            scroll={{ x: 'max-content', y: 'calc(100vh - 320px)' }}
            rowClassName={() => 'font-row-sm'}
            dataSource={results}
          />
        </div>
      </Content>
    </>
  );
};

export default Referred;