import apolloClient from '@config/apolloClient';
import { CREATE_DOCUMENT_MUTATION } from '@mutations/document/create.gpl';
import { DELETE_DOCUMENT_MUTATION } from '@mutations/document/delete.gpl';
import { DOCUMENTS_QUERY } from '@queries/documents.gpl';

export const findDocumentsRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: DOCUMENTS_QUERY
	});

	if (errors) return { errors };

	return { response: data.documents };
};

export const createDocumentRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_DOCUMENT_MUTATION,
    variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createDocument };
};

export const deleteDocumentRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_DOCUMENT_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeDocument };
};