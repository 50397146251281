import dayjs from 'dayjs';
import { BusinessTypes, LicenseTypes, States } from './enums';
import { ILicense } from '@interfaces/license.interface';

export const uploadFile = async (file: string | Blob, type: string, folder: string = '') => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('folder', folder);
  formData.append('file', file);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/upload`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to upload file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteFile = async (url: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/delete`, 
      { 
        method: 'POST', 
        headers: 
          { 
            'Content-Type': 'application/json'
          }, 
        body: JSON.stringify({ url }) 
      }
    );

    if (!response.ok) { throw new Error('Failed to delete file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const sendFeedback = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/feedback`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to send feedback'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const sendTicket = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/support`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to submit ticket'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
  if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

export const getStateName = (abbreviation: string | undefined): string => {
  const stateLookup: Record<string, string> = Object.fromEntries(Object.entries(States).map(([fullName, abbreviation]) => [abbreviation, fullName]));
  return abbreviation ? abbreviation.split(', ').map((abbr) => stateLookup[abbr] || abbr).join(', ') : '';
};

export const getStatesFromLicenses = (licenses: ILicense[]): string => {
  const sortedLicenses = licenses.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  return [...new Set(sortedLicenses.map((license) => license.state))].join(', ');
};

export const formatAsCurrency = (value: number | string, showDecimals = false) => {
  return value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: showDecimals ? 2 : 0 }).format(typeof value === 'string' ? parseFloat(value) : value) : '';
};

export const formatAsPhone = (value: number | string) => {
  if(!value) return '';

  const digits = value.toString().replace(/\D/g, '').slice(0, 10);
  if (digits.length === 0) return '';

  if (digits.length < 4) return `(${digits}`;
  if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6)}`;
};

export const formatAsSSN = (value: number | string) => {
  if(!value) return '';
  const digits = value.toString().replace(/\D/g, '').slice(0, 9);

  if (digits.length === 0) return '';
  if (digits.length <= 3) return digits;
  if (digits.length <= 5) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  return `${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(5)}`;
};

export const formatAsEIN = (value: number | string) => {
  if(!value) return '';
  const digits = value.toString().replace(/\D/g, '').slice(0, 9);

  if (digits.length === 0) return '';
  if (digits.length <= 2) return digits;
  return `${digits.slice(0, 2)}-${digits.slice(2)}`;
};

export const formatDaySuffix = (day: number) => {
  if (day % 10 === 1 && day !== 11) return `${day}st`;
  if (day % 10 === 2 && day !== 12) return `${day}nd`;
  if (day % 10 === 3 && day !== 13) return `${day}rd`;
  return `${day}th`;
}

export const formatDatePost = (date: Date) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffInDays = now.diff(targetDate, 'day');
  const diffInHours = now.diff(targetDate, 'hour');

  if (diffInHours < 24) {
    return targetDate.format('h:mm A');
  } else if (diffInDays === 1) {
    return `Yesterday at ${targetDate.format('h:mm A')}`;
  } else {
    const dayWithSuffix = formatDaySuffix(targetDate.date());
    const formattedDate = targetDate.format('ddd MMM');
    const formattedTime = targetDate.format('h:mm A');
    return `${formattedDate} ${dayWithSuffix}, ${formattedTime}`;
  }
}

export const formatDateChat = (localDate: Date, onlyDay = false) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  const messageDate = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

  if (messageDate.getTime() === today.getTime()) {
    return onlyDay ? 'Today' : localDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  } else if (messageDate.getTime() === yesterday.getTime()) {
    return 'Yesterday ' + localDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  } else {
    return localDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
  }
};

export const formatDateNotification = (localDate: Date) => {
  const now = new Date();
  const timeDifference = now.getTime() - localDate.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation of a month
  const years = Math.floor(days / 365); // Approximation of a year

  if (days < 1) {
    if (hours < 1) {
      if(minutes < 1) {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`
      } else {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      }
    }
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (days === 1) {
    return 'Yesterday';
  } else if (days < 7) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (weeks === 1) {
    return 'Last week';
  } else if (weeks < 4) {
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (months === 1) {
    return 'Last month';
  } else if (months < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else if (years === 1) {
    return 'Last year';
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};

export const sortUsersByLastName = (users: any) => {
  users.sort((a: any, b: any) => {
    if (!a.fullName) return -1;
    if (!b.fullName) return 1;

    const lastNameA = a.fullName.split(' ')[1]?.trim().toLowerCase();
    const lastNameB = b.fullName.split(' ')[1]?.trim().toLowerCase();
  
    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });

  return users;
}

export const exportJsonToCsv = (data: any[], filename: string, header: any[]) => {
  if (!data.length) return;

  const headers = header.join(',') + '\n';
  const rows = data.map(obj => Object.values(obj).map(value => { if (typeof value === 'string') { return `"${value.replace(/"/g, '""')}"`; } return value; }).join(',')).join('\n');

  const csvContent = headers + rows;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const getLicenseTypesText = (type: LicenseTypes) => {
  switch (type) {
    case LicenseTypes.Principal:
      return 'Principal Broker';
    case LicenseTypes.Associate:
      return 'Associate Broker';
    case LicenseTypes.Sales:
      return 'Sales Agent';
    case LicenseTypes.Coordinator:
      return 'Transaction Coordinator';
    case LicenseTypes.Admin:
      return 'Admin Team';
    default:
      return '';
  }
};

export const getBusinessTypesText = (type: BusinessTypes) => {
  switch (type) {
    case BusinessTypes.Individual:
      return 'Individual/Sole Proprietor';
    case BusinessTypes.CorporationC:
      return 'C Corporation';
    case BusinessTypes.CorporationS:
      return 'S Corporation';
    case BusinessTypes.Parntership:
      return 'Partnership';
    case BusinessTypes.Trust:
      return 'Trust/Estate';
    case BusinessTypes.LLC:
      return 'LLC';
    default:
      return '';
  }
};