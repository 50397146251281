/* eslint-disable react-hooks/exhaustive-deps */
import { deleteFile, getStateName, uploadFile } from '@utils/functions';
import { Button, DatePicker, Divider, Input, message, Radio, Select, Table, TableProps, Typography, Upload, UploadFile } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import { useContext, useEffect, useState } from 'react';
import { AuthActions, AuthContext, isUserInAdminRole } from '@shared/context/auth';
import { findOfficesRequest } from '@shared/services/office.service';
import { IUser } from '@interfaces/user.interface';
import dayjs from 'dayjs';
import SocialInput from '@components/SocialInput';
import { Social } from '@shared/utils/enums';
import { findReferredRequest, updateUserRequest } from '@shared/services/user.service';
import { forgotPasswordRequest } from '@shared/services/auth.service';
import { IOffice } from '@interfaces/office.interface';
import InputMask from 'react-input-mask';
import { FiSave } from 'react-icons/fi';

const { Title } = Typography;

const columns: TableProps<IUser>['columns'] = [
  { title: <span className='text-xs'>Name</span>, dataIndex: 'fullName', key: 'fullName' },
  { title: <span className='text-xs'>Date Joined</span>, dataIndex: 'hireDate', key: 'hireDate', render: (date) => date ? dayjs(date).format('MM/DD/YYYY') : '-' }
];

const Profile = ({ tabIndex }: any) => {
  const { authState, dispatch } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [offices, setOffices] = useState<IOffice[]>();
  const [referrals, setReferrals] = useState<IUser[]>();
  const [formData, setFormData] = useState<IUser>({ ...authState.user });
  const [fileList, setFileList] = useState<UploadFile[]>(authState.user.avatar ? [{ uid: '-1', name: 'avatar.png', status: 'done', url: authState.user.avatar, preview: authState.user.avatar }] : []);

  const customFormat = (value: dayjs.Dayjs) => `${value.format('MM/DD/YYYY')}`;
  
  useEffect(() => {
    const fetchData = async () => {
      const office = await findOfficesRequest();
      setOffices(office.response.map((off: any) => ({ value: off.id, label: off.name })));

      const referred = await findReferredRequest();
      setReferrals(referred.response);

      setLoading(false);
    };

    fetchData();
  }, []); 

  useEffect(() => {
    setFormData({ ...authState.user });
  }, [tabIndex]);

  const _onSaveChanges = (values: any, save?: boolean) => {
    setSaving(true);

    updateUserRequest(save ? values : formData)
      .then(() => {
        messageApi.open({ type: 'success', content: 'Profile information updated correctly' });
        setSaving(false);
        dispatch({ action: AuthActions.SETUSER, data: { user: save ? values : formData }});
      })
      .catch(() => {
        setSaving(false);
        messageApi.open({ type: 'error', content: 'Failed to update information' });
      });
  };

  const _onChange = (name: string, value: any, save?: boolean) => {
    setFormData({ ...formData, [name]: value });
    if(save) _onSaveChanges({ ...formData, [name]: value }, true);
  };

  const _onImageChange = ({ file }: any) => {
    uploadFile(file, 'image', 'avatar')
      .then(async (res) => { 
        await deleteFile(formData.avatar!);
        setFileList([{ uid: '-1', name: 'avatar.png', status: 'done', url: res.url, preview: res.url }]);
        _onChange('avatar', res.url, true);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to upload image' }));
  };

  const _onImageDelete = () => {
    deleteFile(formData.avatar!)
      .then((res) => {
        setFileList([]);
        _onChange('avatar', null);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to delete image' }));
  };

  const _onAddSocial = () => {
    if (!formData.social) formData.social = [];
    formData.social.push({ platform: Social.INSTAGRAM, username: '' });
    _onChange('social', formData.social);
  }

  const _onUpdateSocial = (index: number, value: { platform: Social, username: string }) => {
    if(formData.social) formData.social[index] = value;
    _onChange('social', formData.social);
  }

  const _onDeleteSocial = (index: number) => {
    formData.social?.splice(index, 1);
    _onChange('social', formData.social);
  }

  const _onPasswordReset = async () => {
    await forgotPasswordRequest(formData.email!)
      .then((resp: any) => {
        messageApi.open({ type: resp.response.type, content: resp.response.message });
      })
  };

  const uploadImgButton = (
    <button style={{ border: 0, background: 'none'}} type='button'>
      <PlusOutlined />
      <div className='mt-1 text-xs'>Upload Photo</div>
    </button>
  );

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8'>
        <div className='flex justify-between'>
          <Title level={5} className='mt-[-20px] mb-[-20px]'>Public Profile Information</Title>
           <Button className='rounded-xl mt-[-30px] mb-[-20px]' loading={saving} onClick={_onSaveChanges} icon={<FiSave size={20} className='mt-1' />}>Save Changes</Button>
        </div>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4 mb-8'>
          <div className='row-span-2 col-span-2 relative flex items-center justify-center'>
            <Upload
              name='avatar'
              listType='picture-circle'
              className='avatar-uploader'
              fileList={fileList}
              showUploadList={true}
              customRequest={_onImageChange}
              onRemove={_onImageDelete}
            >
              {fileList.length > 0 ? null : uploadImgButton}
            </Upload>
          </div>
          <div className='col-span-4'>
            <div className='text-xs'>Full Name</div>
            <Input 
              className='w-full' 
              placeholder='Full Name cannot be empty' 
              status={formData.fullName ? '' : 'error'} 
              value={formData.fullName} 
              onChange={(e) => _onChange('fullName', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Birth Date</div>
            <DatePicker 
              className='w-full' 
              format={customFormat}
              value={formData.birthDate ? dayjs(formData.birthDate) : null}
              onChange={(e) => _onChange('birthDate', e ? e.toDate() : null)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Hire Date</div>
            <DatePicker 
              disabled={!isUserInAdminRole()}
              format={customFormat}
              className='w-full' 
              value={formData.hireDate ? dayjs(formData.hireDate) : null}
              onChange={(e) => _onChange('hireDate', e ? e.toDate() : null)}
            />
          </div>
          <div className='col-span-2 flex items-center justify-end'>
            <Button className='rounded-xl mt-1 w-full' onClick={_onPasswordReset} type='primary'>Password Reset</Button>
          </div>
          <div className='col-span-4'>
            <div className='text-xs'>Job Title</div>
            <Input 
              className='w-full' 
              value={formData.jobTitle} 
              onChange={(e) => _onChange('jobTitle', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Office</div>
            <Select
              disabled={!isUserInAdminRole()}
              showSearch
              placeholder='Select an office'
              optionFilterProp='label'
              className='w-full'
              options={offices}
              value={formData.officeId}
              onChange={(e) => _onChange('officeId', e)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>EIN/SSN</div>
            {formData.personal ? 
            <InputMask 
              mask='999-99-9999' 
              maskChar='_'
              max={8}
              value={formData.ssn} 
              onChange={(e) => _onChange('ssn', e.target.value.replace(/\D/g, '').slice(0, 9))}
            >
              {(inputProps) => <Input {...inputProps} className='w-full' />}
            </InputMask>
            : 
            <InputMask 
              mask='99-9999999'
              maskChar='_'
              value={formData.ssn} 
              onChange={(e) => _onChange('ssn', e.target.value.replace(/\D/g, '').slice(0, 9))}
            >
              {(inputProps) => <Input {...inputProps} className='w-full' />}
            </InputMask>}
          </div>
          <div className='col-span-2 flex items-center'>
            <Radio.Group 
              className='ssn-radio-group mt-1 flex w-full' 
              block 
              options={[{ label: 'Personal', value: 'Personal' }, { label: 'Business', value: 'Business' }]} 
              onChange={(e)  => _onChange('personal', e.target.value === 'Personal') }
              defaultValue={formData.personal ? 'Personal' : 'Business'}
              optionType='button' 
            />
          </div>
          <div className='col-span-12'>
            <div className='text-xs'>Bio</div>
            <Input.TextArea 
              rows={5} 
              className='w-full'
              value={formData.bio}
              onChange={(e) => _onChange('bio', e.target.value)}
            />
          </div>
          {formData.social?.map((social, idx) =>
            <div className='col-span-3 mt-4'>
              <SocialInput media={social.platform} user={social.username} index={idx} updateFn={_onUpdateSocial} removeFn={_onDeleteSocial}/>
            </div>
          )}
          {(!formData.social || formData.social.length < 4) && 
            <button onClick={_onAddSocial} className='border border-dashed rounded-lg col-span-3 mt-3 flex justify-center items-center bg-orange-50 py-2 px-4'>
              <PlusOutlined />
              <div className='ml-2 whitespace-nowrap'>Add Social Profile</div>
            </button>}
        </div>
        <Title level={5} className='mb-[-20px]'>Contact Information (Private)</Title>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-1 gap-4 mb-8'>
          <div className='col-span-4'>
            <div className='text-xs'>Email Address</div>
            <Input 
              className='w-full' 
              placeholder='Email cannot be empty' 
              status={formData.email ? '' : 'error'} 
              value={formData.email}
              onChange={(e) => _onChange('email', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Primary Phone</div>
            <InputMask 
              mask='(999) 999 9999'
              maskChar='_'
              value={formData.phone}
              onChange={(e: any) => _onChange('phone', e.target.value.replace(/\D/g, '').slice(0, 10))}
            >
              {(inputProps) => <Input {...inputProps} className='w-full' />}
            </InputMask>
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Secondary Phone</div>
            <InputMask 
              mask='(999) 999 9999'
              maskChar='_'
              value={formData.fax}
              onChange={(e: any) => _onChange('fax', e.target.value.replace(/\D/g, '').slice(0, 10))}
            >
              {(inputProps) => <Input {...inputProps} className='w-full' />}
            </InputMask>
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Emergency Phone</div>
            <InputMask 
              mask='(999) 999 9999'
              maskChar='_'
              value={formData.emergency}
              onChange={(e: any) => _onChange('emergency', e.target.value.replace(/\D/g, '').slice(0, 10))}
            >
              {(inputProps) => <Input {...inputProps} className='w-full' />}
            </InputMask>
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Associated State</div>
            <Input 
              className='w-full' 
              disabled
              value={getStateName(formData.state)}
            />
          </div>
        </div>
        <Title level={5} className='mb-[-20px]'>Referred Agents</Title>
        <Divider className='mt-0' />
        <Table<IUser> 
          columns={columns}
          loading={loading}
          size='small'
          className='w-1/2 mt-[-20px]'
          pagination={false}
          dataSource={referrals}
        />
      </Content>
    </>
  );
};

export default Profile;