export enum Role {
  BROKER = 'Broker',
  AGENT = 'Agent',
  AUDITOR = 'Auditor',
}

export enum Social {
  INSTAGRAM = 'INSTAGRAM', 
  TWITTER = 'TWITTER', 
  FACEBOOK = 'FACEBOOK', 
  YOUTUBE = 'YOUTUBE', 
  LINKEDIN = 'LINKEDIN', 
  TIKTOK = 'TIKTOK', 
  TWITCH = 'TWITCH', 
}

export enum StorageKeys {
  USER = 'user',
  TOKEN = 'token',
  REFRESH = 'refresh',
  POST = 'post',
  CHAT = 'chat',
}

export enum NotificationType {
  POST = 'Post',
  MESSAGE = 'Message',
  COMMENT = 'Comment',
}

export enum States {
  Arizona = 'AZ',
  Nevada = 'NV',
  Utah = 'UT',
}

export enum TemplateCategories {
  BUYER = 'Buyer',
  SELLER = 'Seller',
  OTHER = 'Other',
  INTERNAL = 'Internal',
  VIDEO = 'Video',
  COMMERCIAL = 'Commercial',
  MARKETING = 'Marketing',
  MISC = 'Misc',
}

export enum LicenseTypes {
  Principal = 'PRINCIPAL',
  Associate = 'ASSOCIATE',
  Sales = 'SALES',
  Coordinator = 'COORDINATOR',
  Admin = 'ADMIN'
}

export enum BusinessTypes {
  Individual = 'INDIVIDUAL',
  CorporationS = 'S_CORPORATION',
  CorporationC = 'C_CORPORATION',
  Parntership = 'PARTNERSHIP',
  Trust = 'TRUST',
  LLC = 'LLC'
}