import apolloClient from '@config/apolloClient';
import { DEAL_REPORT_QUERY, PAYOUT_REPORT_QUERY, PERSONELLE_REPORT_QUERY, REFERRED_REPORT_QUERY, ROSTER_REPORT_QUERY } from '@queries/report.gpl';

export const dealsReportRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.query({
		query: DEAL_REPORT_QUERY,
    variables: { input: { ...payload }},
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.dealReport };
};

export const referredReportRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: REFERRED_REPORT_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.referredReport };
};

export const personnelReportRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: PERSONELLE_REPORT_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.personnelReport };
};

export const payoutReportRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: PAYOUT_REPORT_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.payoutReport };
};

export const rosterReportRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.query({
		query: ROSTER_REPORT_QUERY,
		variables: { input: { ...payload }},
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.rosterReport };
};