import { gql } from '@apollo/client';

export const ADMIN_USER_OPTIONS_QUERY = gql`
  query UserOptions {
    userOptions {
      offices {
        id
        name
      }
      users {
        id
        fullName
      }
    }
  }
`;