import { gql } from '@apollo/client';

export const TEMPLATES_QUERY = gql`
  query Templates {
    templates {
      id
      url
      name
      category
      clicks
    }
  }
`;
