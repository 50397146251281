import { LockOutlined, ReloadOutlined } from '@ant-design/icons';
import { AuthActions, AuthContext } from '@shared/context/auth';
import { resetPasswordRequest, validateTokenRequest } from '@shared/services/auth.service';
import { getFirebaseDeviceToken } from '@shared/utils/firebase';
import { Form, Input, Button, message } from 'antd';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Reset = () => {
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [isValidToken, setIsValidToken] = useState(false);
  const [code, setCode] = useState(Array(6).fill(''));
  const inputsRef = useRef<any[]>([]);
  
  const onValidate = async (token: string) => {
    const params = new URLSearchParams(window.location.search);
    const hash = params.get('t');

    await validateTokenRequest(hash!, token)
      .then((resp: any) => {
        if(resp.response.type === 'error') {
          messageApi.open({ type: resp.response.type, content: resp.response.message });
        } else {
          setIsValidToken(true);
        }
      });
  };

  const onReset = async () => {
    const formData = form.getFieldsValue();
    const params = new URLSearchParams(window.location.search);
    const hash = params.get('t');

    if(formData.password !== formData.repeated) {
      messageApi.open({ type: 'error', content: 'Passwords do not match' });
    } else {
      await resetPasswordRequest({ hash, password: formData.password, token: await getFirebaseDeviceToken() })
        .then((resp: any) => {
          messageApi.open({ type: 'success', content: 'Password was reset successfully!' });
          dispatch({ action: AuthActions.LOGIN, data: { token: resp.jwtBearer, refresh: resp.jwtRefresh, user: resp.user }});
          navigate('/');
        });
    }
  };

  const handleChange = (value: string, index: number) => {
    if (/^\d?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        inputsRef.current[index + 1]?.focus();
      } else if(value && index === 5) {
        onValidate(newCode.join(''));
      }
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('Text').replace(/\D/g, '');
    if (pastedData.length > 0) {
      const newCode = [...code];
      for (let i = 0; i < Math.min(pastedData.length, 6); i++) {
        newCode[i] = pastedData[i];
      }
      setCode(newCode);

      const nextEmptyIndex = Math.min(pastedData.length, 6) - 1;
      if (inputsRef.current[nextEmptyIndex]) {
        if(nextEmptyIndex === 5) {
          onValidate(newCode.join(''));
        } else {
          inputsRef.current[nextEmptyIndex].focus();
        }
      }
    }
  };

  return (
    <>
      {contextHolder}
      <div className='mb-6 mt-[-20px] text-center'>{!isValidToken ? 'Please, enter the 6-digit code you received' : 'Now, create your new password'}</div>
      {!isValidToken ? 
        <Form form={form} initialValues={{ remember: true }} onFinish={onValidate} className='flex flex-row justify-self-center'>
          {code.map((digit, index) => (
            <Form.Item key={index}>
              <Input
                key={index}
                ref={(el) => (inputsRef.current[index] = el)}
                value={digit}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                className='w-[34px] h-[40px] mx-2'
                inputMode='numeric'
              />
            </Form.Item>
          ))}
        </Form>
        :
        <Form form={form} initialValues={{ remember: true }} onFinish={onReset}>
          <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }, { min: 8, message: 'Password must be at least 8 characters' }]}>
            <Input prefix={<LockOutlined className='site-form-item-icon mr-2' />} type='password' placeholder='Password'/>
          </Form.Item>
          <Form.Item name='repeated' rules={[{ required: true, message: 'Please repeat your Password!' }, { min: 8, message: 'Password must be at least 8 characters' }]}>
            <Input prefix={<ReloadOutlined className='site-form-item-icon mr-2' />} type='password' placeholder='Repeat password'/>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>Reset Password</Button>
          </Form.Item>
        </Form>
      }
    </>
  );
};

export default Reset;
