import { gql } from '@apollo/client';

export const REFERRED_QUERY = gql`
  query Referred {
    referred {
      id
      fullName
      email
      avatar
      phone
      officeId
      hireDate
    }
  }
`;
