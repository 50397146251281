import { IOffice } from '@interfaces/office.interface';
import { findOfficesRequest } from '@shared/services/office.service';
import { Table, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';

const { Title } = Typography;

const columns = [
  { title: 'Name', dataIndex: 'name', width: 180, editable: true, fixed: true },
  { title: 'Address', dataIndex: 'address', width: 350, editable: true },
  { title: 'Email', dataIndex: 'email', width: 180, editable: true, validationRules: [{ type: 'email', message: 'Invalid email format' }] },
  { title: 'Phone', dataIndex: 'phone', width: 100, editable: true, inputType: 'phone' },
];

const Offices = () => {
  const [loading, setLoading] = useState(true);
  const [offices, setOffices] = useState<IOffice[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await findOfficesRequest();
      setOffices(result.response.filter((office: any) => office.id !== 1)); //Do not show "All"
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, []); 

  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 m-2 flex flex-col h-[97%]'>
      <Title level={5} className='ml-4'>Offices</Title>
      <div className='h-[calc(100vh-180px)] mt-4 mx-2'>
        <Table
          columns={columns}
          loading={loading}
          size='small'
          pagination={{ 
            pageSizeOptions: ['25', '50', '100'], 
            defaultPageSize: 50,
          }}
          scroll={{ y: 'calc(100vh - 200px)' }}
          rowClassName={() => 'font-row-sm'}
          dataSource={offices}
        />
      </div>
    </Content>
  );
};

export default Offices;