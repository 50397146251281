import apolloClient from '@config/apolloClient';
import { DASHBOARD_QUERY, TOP_AGENTS_QUERY } from '@queries/dashboard.gpl';

export const dashboardRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: DASHBOARD_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.dashboard };
};

export const topAgentsRequest = async (period: string) => {
	const { data, errors } = await apolloClient.query({
		query: TOP_AGENTS_QUERY,
    variables: { period }
	});

	if (errors) return { errors };

	return { response: data.topAgents };
};