import MaskedCell from '@components/MaskedCell';
import { payoutReportRequest } from '@shared/services/report.service';
import { BusinessTypes } from '@shared/utils/enums';
import { exportJsonToCsv, formatAsEIN, formatAsSSN, getBusinessTypesText, getStateName } from '@shared/utils/functions';
import { Button, message, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useState } from 'react';
import { FaFileCsv } from 'react-icons/fa6';
import { MdOutlineQueryStats } from 'react-icons/md';

const columns = [
  { title: <span className='text-sm'>Agent Name</span>, width: 150, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.agentname.localeCompare(b.agentname) },
  { title: <span className='text-sm'>Office</span>, width: 180, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
  { title: <span className='text-sm'>W9: Entity Name</span>, width: 130, dataIndex: 'business', key: 'business', render: (value: string) => value ?? '-' },
  { title: <span className='text-sm'>W9: Entity Type</span>, width: 130, dataIndex: 'type', key: 'type', render: (value: BusinessTypes) => { return getBusinessTypesText(value) ?? '-' }},
  { title: <span className='text-sm'>W9: Full Address</span>, width: 180, dataIndex: 'address', key: 'address', render: (value: string) => value ?? '-' },
  { title: <span className='text-sm'>W9: State</span>, width: 130, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value), sorter: (a: any, b: any) => a.state.localeCompare(b.state) },
  { title: <span className='text-sm'>SSN</span>, width: 130, dataIndex: 'ssn', key: 'ssn', render: (ssn: string, record: any) => (record.personal ? <MaskedCell value={formatAsSSN(ssn)} /> : '-') },
  { title: <span className='text-sm'>EIN</span>, width: 130, dataIndex: 'ssn', key: 'ssn', render: (ssn: string, record: any) => (!record.personal ? formatAsEIN(ssn) : '-') },
  
];

const Payout = () => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useState<any[]>([]);

  const _OnRunReport = () => {
    setFetching(true);

    payoutReportRequest()
      .then((res: any) => {
        setResults(res.response);
        setFetching(false);
      });
  };

  const _OnExportReport = () => {
    if(results.length > 0) {
      const csvData = results.map(({ __typename, agentname, officename, business, type, address, state, ssn, personal, ...rest }) => ({ agentname, officename, business, type: getBusinessTypesText(type), address, state,  ssn: personal ? ssn : '', ein: !personal ? ssn : '', ...rest }));
      exportJsonToCsv(csvData, `Payout_Report_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Agent Name', 'Office', 'W9: Name of Individual/Entity', 'W9: Entity Type', 'W9: Address', 'W9: State', 'SSN', 'EIN']);
      messageApi.open({ type: 'success', content: 'Report data exported correctly' });
    } else {
      messageApi.open({ type: 'error', content: 'There are no records to export' });
    }
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 h-[97%]'>
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4'>
          <div className='col-span-9'></div>
          <div className='col-span-3 flex items-center justify-end'>
            <Button className='rounded-xl' onClick={_OnRunReport} icon={<MdOutlineQueryStats size={20} className='mt-1' />}>Run Report</Button>
            <Button className='rounded-xl ml-2' onClick={_OnExportReport} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
          </div>
        </div>
        <div className='h-[calc(100vh-280px)] mt-[-30px]'>
          <Table
            columns={columns}
            loading={fetching}
            size='small'
            pagination={{ 
              pageSizeOptions: ['25', '50', '100'], 
              defaultPageSize: 50,
              total: results.length,
              showTotal: (total) => `${total} Results`,
            }}
            scroll={{ x: 'max-content', y: 'calc(100vh - 320px)' }}
            rowClassName={() => 'font-row-sm'}
            dataSource={results}
          />
        </div>
      </Content>
    </>
  );
};

export default Payout;