import { gql } from '@apollo/client';

export const POST_QUERY = gql`
  query Post($id: Int!) {
    post(id: $id) {
      id
      title
      body
      attachments
      author {
        id
        fullName
        avatar
      }
      comments {
        id
        body
        createdAt
        author {
          id
          fullName
          avatar
        }
      }
      expireDate
      dashboard
      createdAt
    }
  }
`;

export const POSTS_QUERY = gql`
  query Posts {
    posts {
      id
      title
      body
      attachments
      author {
        id
        fullName
        avatar
      }
      comments {
        id
        body
        createdAt
        author {
          id
          fullName
          avatar
        }
      }
      expireDate
      dashboard
      createdAt
    }
  }
`;
