import { personnelReportRequest } from '@shared/services/report.service';
import { LicenseTypes } from '@shared/utils/enums';
import { exportJsonToCsv, getLicenseTypesText, getStateName } from '@shared/utils/functions';
import { Button, message, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FaFileCsv } from 'react-icons/fa6';
import { MdOutlineQueryStats } from 'react-icons/md';

const columns = [
  { title: <span className='text-sm'>Agent Name</span>, width: 210, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.fullName.localeCompare(b.fullName) },
  { title: <span className='text-sm'>Name on License</span>, width: 210, dataIndex: 'licensename', key: 'licensename' },
  { title: <span className='text-sm'>State</span>, width: 130, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
  { title: <span className='text-sm'>License #</span>, width: 150, dataIndex: 'number', key: 'number' },
  { title: <span className='text-sm'>License Type</span>, width: 160, dataIndex: 'type', key: 'type', render: (value: string) => getLicenseTypesText(value as LicenseTypes) },
  { title: <span className='text-sm'>Issue Date</span>, width: 120, dataIndex: 'issuedate', key: 'issuedate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
  { title: <span className='text-sm'>Expire Date</span>, width: 120, dataIndex: 'expiredate', key: 'expiredate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
];

const Personnel = () => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useState<any[]>([]);

  const _OnRunReport = () => {
    setFetching(true);

    personnelReportRequest()
      .then((res: any) => {
        setResults(res.response);
        setFetching(false);
      });
  };

  const _OnExportReport = () => {
    if(results.length > 0) {
      const csvData = results.map(({ __typename, type, issuedate, expiredate, ...rest }) => ({ ...rest, type: getLicenseTypesText(type as LicenseTypes) , issuedate: issuedate ? dayjs(issuedate).format('MM/DD/YYYY') : '', expiredate: expiredate ? dayjs(expiredate).format('MM/DD/YYYY') : '' }));
      exportJsonToCsv(csvData, `Licensing_Report_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Agent Name', 'Name on License', 'State', 'License #', 'License Type', 'Issue Date', 'Expire Date']);
      messageApi.open({ type: 'success', content: 'Report data exported correctly' });
    } else {
      messageApi.open({ type: 'error', content: 'There are no records to export' });
    }
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 h-[97%]'>
      <div className='w-full grid grid-cols-12 grid-rows-2 gap-4'>
          <div className='col-span-9'></div>
          <div className='col-span-3 flex items-center justify-end'>
            <Button className='rounded-xl' onClick={_OnRunReport} icon={<MdOutlineQueryStats size={20} className='mt-1' />}>Run Report</Button>
            <Button className='rounded-xl ml-2' onClick={_OnExportReport} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
          </div>
        </div>
        <div className='h-[calc(100vh-280px)] mt-[-30px]'>
          <Table
            columns={columns}
            loading={fetching}
            size='small'
            pagination={{ 
              pageSizeOptions: ['25', '50', '100'], 
              defaultPageSize: 50,
              total: results.length,
              showTotal: (total) => `${total} Results`,
            }}
            scroll={{ x: 'max-content', y: 'calc(100vh - 320px)' }}
            rowClassName={() => 'font-row-sm'}
            dataSource={results}
          />
        </div>
      </Content>
    </>
  );
};

export default Personnel;