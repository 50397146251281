import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query Users($input: FindUserInput!) {
    users(findUserInput: $input) {
      id
      fullName
      role
      email
      phone
      officeId
      active
      hireDate
      referrerId
      admin
      avatar
    }
  }
`;
