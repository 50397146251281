import { IOffice } from '@interfaces/office.interface';
import { IUser } from '@interfaces/user.interface';
import { isUserInAdminRole } from '@shared/context/auth';
import { dealsReportRequest } from '@shared/services/report.service';
import { userOptionRequest } from '@shared/services/user.service';
import { exportJsonToCsv, formatAsCurrency, getStateName } from '@shared/utils/functions';
import { Button, DatePicker, message, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { FaFileCsv } from 'react-icons/fa6';
import { MdOutlineQueryStats } from 'react-icons/md';
import { SortOrder } from 'antd/es/table/interface';

const Deals = () => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [selectedOffices, setSelectedOffices] = useState<number[]>([]);
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<number[]>([]);
  const [agents, setAgents] = useState<IUser[]>([]);
  const [results, setResults] = useState<any[]>([]);

  const customFormat = (value: dayjs.Dayjs) => `${value.format('MM/DD/YYYY')}`;

  const columns = isUserInAdminRole() ? [
    { title: <span className='text-sm'>Close Date</span>, defaultSortOrder: 'ascend' as SortOrder, sorter: (a: any, b: any) => new Date(a.closingdate).getTime() - new Date(b.closingdate).getTime(), width: 120, dataIndex: 'closingdate', key: 'closingdate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-'  },
    { title: <span className='text-sm'>Address</span>, width: 320, dataIndex: 'address', key: 'address' },
    { title: <span className='text-sm'>Deal Value</span>, width: 120, dataIndex: 'saleprice', key: 'saleprice', render: (value: any) => formatAsCurrency(value)},
    { title: <span className='text-sm'>Gross Commission</span>, width: 140, dataIndex: 'commission', key: 'commission', render: (value: any) => formatAsCurrency(value, true)},
    { title: <span className='text-sm'>State</span>, width: 120, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
    { title: <span className='text-sm'>Transaction Type</span>, width: 130, dataIndex: 'type', key: 'type' },
    { title: <span className='text-sm'>Office</span>, width: 180, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
    { title: <span className='text-sm'>Agent</span>, width: 210, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.agentname.localeCompare(b.agentname) },
  ] : [
    { title: <span className='text-sm'>Close Date</span>, defaultSortOrder: 'ascend' as SortOrder, sorter: (a: any, b: any) => new Date(a.closingdate).getTime() - new Date(b.closingdate).getTime(), width: 120, dataIndex: 'closingdate', key: 'closingdate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-'  },
    { title: <span className='text-sm'>Address</span>, width: 320, dataIndex: 'address', key: 'address' },
    { title: <span className='text-sm'>Deal Value</span>, width: 120, dataIndex: 'saleprice', key: 'saleprice', render: (value: any) => formatAsCurrency(value)},
    { title: <span className='text-sm'>Gross Commission</span>, width: 140, dataIndex: 'commission', key: 'commission', render: (value: any) => formatAsCurrency(value, true)},
    { title: <span className='text-sm'>State</span>, width: 120, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
    { title: <span className='text-sm'>Transaction Type</span>, width: 80, dataIndex: 'type', key: 'type' },
    { title: <span className='text-sm'>Office</span>, width: 180, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
  ];

  useMemo(() => {
    const fetchData = async () => {
      const options = await userOptionRequest();
      setOffices(options.response.offices.filter((office: any) => office.id !== 1).map((office: any) => ({ value: office.id, label: office.name })));
      setAgents( options.response.users.map((user: any) => ({ value: user.id, label: user.fullName })));

      setLoading(false);
    };

    fetchData();
  }, []); 

  const _OnRunReport = () => {
    setFetching(true);

    dealsReportRequest({ startDate, endDate, agents: selectedAgents, offices: selectedOffices })
      .then((res: any) => {
        setResults(res.response);
        setFetching(false);
      });
  };

  const _OnExportReport = () => {
    if(results.length > 0) {
      const csvData = results.map(({ __typename, closingdate, ...rest }) => ({ closingdate: closingdate ? dayjs(closingdate).format('MM/DD/YYYY') : '', ...rest }));
      exportJsonToCsv(csvData, `Agent_Deals_Report_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Close Date', 'Address', 'Deal Value', 'Gross Commission', 'State', 'Transaction Type', 'Agent', 'Office']);
      messageApi.open({ type: 'success', content: 'Report data exported correctly' });
    } else {
      messageApi.open({ type: 'error', content: 'There are no records to export' });
    }
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 h-[97%]'>
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4 mb-4'>
          <div className='col-span-3 flex items-center'>
            <div className='whitespace-nowrap mr-2'>Start Date: </div>
            <DatePicker 
              format={customFormat}
              className='w-full'
              maxDate={endDate ? dayjs(endDate) : undefined}
              minDate={dayjs().startOf('year')}
              value={startDate}
              onChange={setStartDate}
            />
          </div>
          <div className='col-span-6 flex items-center'>
            <div className='mr-2'>Office: </div>
            <Select
                mode='multiple'
                showSearch
                placeholder='Select an office'
                optionFilterProp='label'
                className='w-full mr-8'
                loading={loading}
                options={offices}
                value={selectedOffices}
                onChange={setSelectedOffices}
              />
          </div>
          <div className='col-span-3 flex items-center justify-end'>
            <Button className='rounded-xl' onClick={_OnRunReport} icon={<MdOutlineQueryStats size={20} className='mt-1' />}>Run Report</Button>
            <Button className='rounded-xl ml-2' onClick={_OnExportReport} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
          </div>
          <div className='col-span-3 flex items-center'>
            <div className='whitespace-nowrap mr-2'>End Date: </div>
            <DatePicker 
              format={customFormat}
              className='w-full'
              minDate={startDate ? dayjs(startDate) : undefined}
              maxDate={dayjs().endOf('year')}
              value={endDate}
              onChange={setEndDate}
            />
          </div>
          <div className='col-span-6 flex items-center'>
            {isUserInAdminRole() && <div className='mr-2'>Agent: </div>}
            {isUserInAdminRole() && <Select
                mode='multiple'
                showSearch
                placeholder='Select an agent'
                optionFilterProp='label'
                className='w-full mr-8'
                loading={loading}
                options={agents}
                value={selectedAgents}
                onChange={setSelectedAgents}
              />
            }
          </div>
          <div className='col-span-3'></div>
        </div>
        <div className='h-[calc(100vh-320px)]'>
          <Table
            columns={columns}
            loading={fetching}
            size='small'
            pagination={{ 
              pageSizeOptions: ['25', '50', '100'], 
              defaultPageSize: 50,
              total: results.length,
              showTotal: (total) => `${total} Results`,
            }}
            scroll={{ x: 'max-content', y: 'calc(100vh - 420px)' }}
            rowClassName={() => 'font-row-sm'}
            dataSource={results}
          />
        </div>
      </Content>
    </>
  );
};

export default Deals;