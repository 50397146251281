/* eslint-disable react-hooks/exhaustive-deps */
import ContactSearchInput from '@components/ContactSearch';
import { AuthContext } from '@shared/context/auth';
import { createChatroomRequest, findChatroomsRequest } from '@shared/services/chatroom.service';
import { Button, Popover, Spin, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useContext, useEffect, useState } from 'react';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import ChatroomCard from '@components/ChatroomCard';
import { findMessagesRequest } from '@shared/services/message.service';
import PrivateMessages from '@components/PrivateMessages';
import { useLocation } from 'react-router-dom';
import { HiPencilAlt } from 'react-icons/hi';

const { Title } = Typography;

const Chatroom = () => {
  const { state } = useLocation();
  const { authState } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [chatroom, setChatroom] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [chatrooms, setChatrooms] = useState<any[]>([]);
  
  const fetchChatrooms = async () => {
    const result = await findChatroomsRequest(authState.user.id);
    setChatrooms(result.response.map((chatroom: any) => ({ ...chatroom, recipient: chatroom.users.find((user: any) => user.id !== authState.user.id) }) ));

    if(state) {
      setChatroom(result.response.find((chatroom: any) => chatroom.id === Number(state.chatroomId)));
    }

    setLoading(false);
  };

  const fetchMessages = async (roomId?: number) => {
    findMessagesRequest(roomId ?? chatroom.id)
      .then((res) => {
        setMessages(res.response);
        setFetching(false);
      });
  };

  useEffect(() => {
    window.addEventListener('new_message', async (e: any) => {
      fetchChatrooms();
      setChatroom((previous: any) => {
        if (previous && previous.id === Number(e.detail.chatroom)) fetchMessages(previous.id);
        return previous;
      });
    });

    return () => { window.removeEventListener('new_message', () => {}) };
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchChatrooms();
  }, []); 

  useEffect(() => {
    if(chatroom) {
      setFetching(true);
      fetchMessages();
    }
  }, [chatroom]);

  const _onContactSearch = (id: number) => {
    const room = chatrooms.find(chatroom => id === authState.user.id 
        ? chatroom.users.length === 1 && chatroom.users[0].id === authState.user.id
        : chatroom.users.some((user: any) => user.id === id) && chatroom.users.some((user: any) => user.id === authState.user.id)
    );

    if(room) {
      _onContactSelect(room.id);
    } else {
      setFetching(true);
      
      createChatroomRequest({ participants: [authState.user.id, id]})
        .then((res) => {
          setChatrooms([{ ...res.response, recipient: res.response.users.find((user: any) => user.id !== authState.user.id) }, ...chatrooms]);
          setChatroom({ ...res.response, recipient: res.response.users.find((user: any) => user.id !== authState.user.id) });
        });
    }
  };

  const _onContactSelect = (id: number) => {
    setChatroom(chatrooms.find(chatroom => chatroom.id === id));
    setChatrooms(chatrooms.map((room: any) => {
        if (room.id === id && room.last && room.last.authorId !== authState.user.id) {
          return { ...room, last: { ...room.last, read: true }}
        }
        return room;
    }));
  };

  const _onSendMessage = (message: any) => {
    setChatrooms([ { ...chatroom, last: message }, ...chatrooms.filter((room) => room.id !== chatroom.id)]);
    setMessages([ message, ...messages ]);
  };

  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 m-2 flex flex-col h-[97%] overflow-hidden'>
      <Title level={5} className='ml-4'>Messaging</Title>
      <div className='flex-grow grid grid-cols-10 gap-2 mx-2 overflow-hidden'>
        <div className='col-span-3 ml-2'>
          {loading && (
            <div className='flex flex-col h-full justify-center items-center'>
              <Spin size='large' className='mb-4' />
              <span>Loading Chats</span>
            </div>
          )}
          <div className='bg-white h-full overflow-auto relative'>
            {chatrooms.map((room: any) => (
              <ChatroomCard selected={chatroom?.id === room.id} room={room} onSelect={_onContactSelect} />
            ))}
            <Popover content={<ContactSearchInput selectedContact={_onContactSearch} />} title='Send New Message' trigger='click' placement='topLeft'>
              <Button type='primary' shape='circle' title='New Message' icon={<HiPencilAlt className='text-xl'/>} size='large' className='absolute bottom-4 right-4 shadow-lg' />
            </Popover>
          </div>
        </div>
        {fetching ? (
          <div className='col-span-7 border-2 flex flex-col items-center justify-center'>
            <Spin size='large' className='mb-4' />
            <span>Loading Messages</span>
          </div>
        ) : chatroom ? (
          <PrivateMessages chatroom={chatroom} messages={messages} sendMessage={_onSendMessage}/>
        ) : (
          <div className='col-span-7 border-2 flex flex-col items-center justify-center'>
            <HiChatBubbleLeftRight size={50} className='mb-4 text-distinctive-gold' />
            <span className='text-lg'>Select a contact to start a conversation</span>
          </div>
        )}
      </div>
    </Content>
  ); 
};

export default Chatroom;