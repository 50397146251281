import { ITemplate } from '@interfaces/template.interface';
import { isUserInAdminRole } from '@shared/context/auth';
import { clickedTemplateRequest, createTemplateRequest, deleteTemplateRequest } from '@shared/services/template.service';
import { TemplateCategories } from '@shared/utils/enums';
import { uploadFile } from '@shared/utils/functions';
import { Card, Form, Input, message, Modal, Popconfirm, Spin, Upload } from 'antd';
import { useState } from 'react';
import { FaFileVideo, FaYoutube } from 'react-icons/fa';
import { MdDelete, MdNoteAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

const TemplateCard = ({ className = '', title, category, files, fetching, add, remove, update }: any) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoName, setVideoName] = useState('');

  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  const isValidUrl = (url: string): boolean => urlRegex.test(url);

  const _onAddTemplate = async ({ file }: any) => {
    setUploading(true);
    const upload = await uploadFile(file, 'template', 'document')

    createTemplateRequest({ category, url: upload.url, name: file.name })
      .then((res) => {       
        add(res.response);
        setUploading(false);
        messageApi.open({ type: 'success', content: 'Template uploaded correctly' });
      });
  };

  const _onDeleteTemplate = async (id: number) => {
    await deleteTemplateRequest(id);
    remove(id);
    messageApi.open({ type: 'success', content: 'Template deleted correctly' });
  }

  const _onAddVideo = () => {
    if(!videoUrl || !isValidUrl(videoUrl)) return;

    setUploading(true);
    setIsModalOpen(false);

    createTemplateRequest({ category, url: videoUrl, name: videoName ? videoName : videoUrl })
      .then((res) => {       
        add(res.response);
        setUploading(false);
        messageApi.open({ type: 'success', content: 'Training Video added correctly' });
      });
  }

  const _onTemplateClick = async (id: number) => {
    await clickedTemplateRequest(id);
    update(id);
  };

  return (
    <>
      {contextHolder}
      <Card className={`shadow-lg ${className}`}>
        <div className='flex justify-between mb-4 items-center'>
          <div className='text-black font-bold text-base'>{title}</div>
          {isUserInAdminRole() && (
            uploading ? 
            <Spin /> : 
            (category === TemplateCategories.VIDEO ? <MdNoteAdd title='Add Video' size={20} className='text-distinctive-gold cursor-pointer' onClick={() => setIsModalOpen(true)}/> : 
            <Upload className='cursor-pointer' showUploadList={false} customRequest={_onAddTemplate}>
              <MdNoteAdd title='Add Template' size={20} className='text-distinctive-gold'/>
            </Upload>)
          )}
        </div>
        {fetching ? 
          <div className='flex-grow flex items-center justify-center h-[30vh]'>
            <Spin size='large' />
          </div> : 
          <div className='overflow-y-auto min-h-[30vh] max-h-[35vh] mb-0'>
            {files.map((template: ITemplate, idx: number) => 
              <div key={idx} className='w-full flex justify-between items-center my-1'>
                <Link onClick={() => _onTemplateClick(template.id)} target='_blank' to={template.url} className='max-w-[90%] truncate block overflow-hidden whitespace-nowrap text-ellipsis'>{template.name}</Link>
                {isUserInAdminRole() && 
                <Popconfirm title={`Delete this Template?`} icon={null} okText='Yes' cancelText='No' onConfirm={() => _onDeleteTemplate(template.id)}>
                  <MdDelete title='Delete Template' size={15} className='text-distinctive-gold cursor-pointer' />
                </Popconfirm>}
              </div>
            )}
          </div>
        }
      </Card>
      <Modal 
        title='Add Training Video' 
        open={isModalOpen} 
        onOk={_onAddVideo} 
        onCancel={() => setIsModalOpen(false)}
        okText='Add Video'
      >
        <Form.Item validateStatus={!videoUrl || !isValidUrl(videoUrl) ? 'error' : ''} help={!videoUrl ? 'Video Url cannot be empty' : (!isValidUrl(videoUrl) ? 'Video Url must have a valid format' : '' )}>
          <Input onChange={(e) => setVideoUrl(e.target.value)} value={videoUrl} placeholder='Enter Youtube video URL' className='mt-4' prefix={<FaYoutube className='mr-2 text-red-600' size={18}/>} />
        </Form.Item>
        <Input onChange={(e) => setVideoName(e.target.value)} value={videoName} placeholder='Enter Display Name' className='mb-4' prefix={<FaFileVideo className='mr-2 text-distinctive-gold' size={18}/>} />
      </Modal>
    </>

  );
};

export default TemplateCard;