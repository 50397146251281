import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(updateUserInput: $input) {
			id
			fullName
			email
			password
			role
			ssn
			personal
			birthDate
			hireDate
			jobTitle
			officeId
			bio
			phone
			fax
			emergency
			avatar
			social
		}
	}
`;