import gql from 'graphql-tag';

export const RESET_PASSWORD_MUTATION = gql`
	mutation ResetPassword($input: ResetPasswordInput!) {
		resetPassword(input: $input) {
      jwtBearer
      jwtRefresh
      user {
        id
        fullName
        email
        password
        admin
        birthDate
        hireDate
        jobTitle
        ssn
			  personal
        officeId
        role
        bio
        phone
        fax
        emergency
        avatar
        social
        state
      }
		}
	}
`;
