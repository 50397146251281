import { gql } from '@apollo/client';

export const DOCUMENTS_QUERY = gql`
  query Documents {
    documents {
      id
      url
      name
      extension
      size
      modifyDate
      createdAt
    }
  }
`;
