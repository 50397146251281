import TockifyCalendar from '@components/TockifyCalendar';
import { Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';

const { Title } = Typography;

const Calendar = () => {
  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 m-2 flex flex-col h-[97%]'>
      <Title level={5} className='ml-4'>Calendar</Title>
      <div className='mx-2 rounded-lg overflow-auto h-full'>
        <TockifyCalendar mode='calendar'/>
      </div>
    </Content>
  );
};

export default Calendar;
