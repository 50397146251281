import { gql } from '@apollo/client';

export const CREATE_TEMPLATE_MUTATION = gql`
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(createTemplateInput: $input) {
      id
      url
      name
      category
      clicks
    }
  }
`;