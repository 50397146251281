import { PaperClipOutlined } from '@ant-design/icons';
import { isUserInAdminRole } from '@shared/context/auth';
import { createPostRequest } from '@shared/services/post.service';
import { StorageKeys } from '@shared/utils/enums';
import { deleteFile, uploadFile } from '@shared/utils/functions';
import { Button, DatePicker, Divider, Input, message, Upload, UploadFile } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IoSendSharp } from 'react-icons/io5';

const { TextArea } = Input;

const customFormat = (value: dayjs.Dayjs) => `${value.format('MM/DD/YYYY')}`;

const BoardPostInput = ({ user, createPost }: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [titleText, setTitleText] = useState('');
  const [postText, setPostText] = useState('');
  const [showUntil, setShowntil] = useState<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);

  useEffect(() => {
    const storedFiles = localStorage.getItem(StorageKeys.POST);

    if(storedFiles) {
      setAttachments(JSON.parse(storedFiles));
      setFileList(JSON.parse(storedFiles).map((file: any) => { return { uid: file.url, name: file.url, status: 'done', url: file.url } }));
    }
  }, []);
  
  const _onImageChange = ({ file }: any) => {
    setFileList([ ...fileList, { uid: '-1', name: 'avatar.png', status: 'uploading', url: '' } ]);

    uploadFile(file, 'image', 'post')
      .then(async (res) => { 
        localStorage.setItem(StorageKeys.POST, JSON.stringify([ ...attachments, { url: res.url } ]));
        setAttachments([ ...attachments, { url: res.url } ]);
        setFileList([ ...fileList, { uid: res.fileName, name: res.fileName, status: 'done', url: res.url } ]);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to upload image' }));
  };

  const _onImageDelete = (file: UploadFile) => {
    const removed = fileList?.filter((file) => file.status === 'removed');

    localStorage.setItem(StorageKeys.POST, JSON.stringify(removed.map((file) => { return { url: file.url } })));
    setAttachments(removed.map((file) => { return { url: file.url } }));
    setFileList(removed);

    deleteFile(file.url!);
  };

  const _onCreatePost = () => {
    setIsPosting(true);

    createPostRequest({ authorId: user.id, attachments, title: titleText, body: postText, expireDate: showUntil, dashboard: isUserInAdminRole() })
      .then((res) => {
        setTitleText('');
        setPostText('');
        setShowntil(null);
        setFileList([]);
        setAttachments([]);
        setIsPosting(false);
        createPost(res.response.id, titleText, postText, res.response.expireDate, res.response.createdAt, attachments);
        localStorage.removeItem(StorageKeys.POST);

        messageApi.open({ type: 'success', content: 'Message Board post created successfully' });
      })
  };
  
  return (
    <>
      {contextHolder}
      <div className='w-full mt-4 flex justify-center flex-col items-center'>
        <div className='bg-white flex border-distinctive-gold border-2 w-3/4 items-center rounded-xl mr-4'>
          <div className='w-full relative border-r-2 border-distinctive-gold'>
            <div className='w-full flex '>
              <Input 
                placeholder={isFocused ? 'Enter your title' : 'What would you like to share?'}
                variant='borderless'
                value={titleText}
                onFocus={() => setIsFocused(true)}
                onChange={(e) => setTitleText(e.target.value)}
              />
              {(isFocused || titleText.length > 0) &&
                <DatePicker 
                  variant='borderless'
                  placeholder='Display Until'
                  className='my-2 w-1/5'
                  format={customFormat}
                  value={showUntil ? dayjs(showUntil) : null}
                  onChange={(e) => setShowntil(e ? e.toDate() : null)}
                />
              }
            </div>
            {(isFocused || titleText.length > 0) &&
              <TextArea
                variant='borderless'
                className='w-full my-1'
                placeholder='Enter your post content'
                style={{ resize: 'none' }}
                rows={isFocused || postText ? 5 : 1}
                value={postText}
                onBlur={(e) => setIsFocused(false)}
                onChange={(e) => setPostText(e.target.value)}
              />
            }
            <Upload accept='.png,.jpg,.jpeg' className={`border-0 absolute right-[15px] bottom-${isFocused ? '[6px]' : '[4px]'} cursor-pointer`} showUploadList={false} customRequest={_onImageChange}>
              <PaperClipOutlined title='Attach files' className='text-xl text-distinctive-gold'/>
            </Upload>
            {fileList.length > 0 && 
              <Upload
                listType='picture-card'
                className='avatar-uploader m-2'
                fileList={fileList}
                showUploadList={true}
                customRequest={() => {}}
                onRemove={_onImageDelete}
              />
            }
          </div>
          <Button className='border-0 self-end w-12 my-1 ml-1 !text-[#1677FF]' loading={isPosting} disabled={!titleText || !postText}>
            <IoSendSharp className='text-xl' title='Create post' onClick={_onCreatePost}/>
          </Button>
        </div>
        <div className='w-3/4 mr-4'>
          <Divider className='bg-distinctive-gold'/>
        </div>
      </div>
    </>
  );
};

export default BoardPostInput;