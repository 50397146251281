/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { createOfficeRequest, deleteOfficeRequest, findOfficesRequest, updateOfficeRequest } from '@services/office.service';
import { IOffice } from 'src/app/interfaces/office.interface';
import EditableTable from '@components/EditableTable';
import { formatAsPhone } from '@shared/utils/functions';

const columns = [
  { title: 'Name', dataIndex: 'name', width: 180, editable: true, fixed: true },
  { title: 'Address', dataIndex: 'address', width: 350, editable: true },
  { title: 'Email', dataIndex: 'email', width: 120, editable: true, validationRules: [{ type: 'email', message: 'Invalid email format' }] },
  { title: 'Phone', dataIndex: 'phone', width: 150, editable: true, inputType: 'phone', render: (value: string) => formatAsPhone(value) },
  { title: 'Agents', dataIndex: 'users', width: 80, editable: false },
];

const Office = () => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<any>(null);
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await findOfficesRequest();
      setOffices(result.response);
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, []); 
  
  const _FilterOffice = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const _AddOffice = () => {
    createOfficeRequest()
      .then((resp) => {
        const newData: IOffice = { id: resp.response.id, name: undefined, address: undefined, email: undefined, phone: undefined };
        setOffices([newData, ...offices]);
        setSelected(newData);
      })
      .catch((err) => {
        console.log('🚀 ~ err', err);
      });
  };

  const _UpdateOffice = async (row: any) => {
    const newRow = { ...row, phone: row.phone?.replace(/\D/g, '').slice(0, 10) };

    const cloned = [...offices];
    const index = cloned.findIndex((item) => row.id === item.id);
    
    if (index > -1) {
      const item = cloned[index];
      cloned.splice(index, 1, { ...item, ...newRow });
      setOffices([...cloned]);
    }

    await updateOfficeRequest(newRow);
  };

  const _DeleteOffice = async (id: number) => {
    const cloned = [...offices];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setOffices([...cloned]);
    }

    await deleteOfficeRequest(id);
  };

  return (
    <Content className='px-4 py-8'>
      <div className='mt-[-20px] flex justify-between mb-4'>
        <Input placeholder='Search' className='rounded-xl w-[25%]' onChange={_FilterOffice}></Input>
        <Button className='rounded-xl' onClick={_AddOffice} type='primary'>Add Office</Button>
      </div>
      <div className='h-[calc(100vh-320px)]'>
        <EditableTable 
          source={offices.filter((item) => !item.name || item.name.toLowerCase().includes(searchTerm.toLowerCase()) )} 
          columns={columns}
          loading={loading}
          selection={selected}
          name='Office'
          updateFn={_UpdateOffice}
          deleteFn={_DeleteOffice}
          scrollYValue='calc(100vh - 380px)'
        />
      </div>
    </Content>
  );
};

export default Office;