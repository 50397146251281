import { refreshTokenRequest } from '@shared/services/auth.service';
import { StorageKeys } from '@shared/utils/enums';
import { createContext, useEffect, useReducer } from 'react';

export interface IAuthContextState {
  user?: any;
  token?: any;
  refresh?: any;
  loading?: boolean;
}
export interface IAuthProviderPayload {
  action: AuthActions;
  data: IAuthContextState;
}

export interface IAuthContextProps {
  authState: IAuthContextState;
  dispatch: (action: IAuthProviderPayload) => void;
}

const initialState = {
  user: null,
  token: null,
  loading: true,
};

export enum AuthActions {
  SETTOKEN,
  SETUSER,
  LOGIN,
  LOGOUT,
  SETLOADING,
}

export const AuthContext = createContext<IAuthContextProps>(
  {} as IAuthContextProps
);

const isTokenExpired = (token: any) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return Date.now() >= payload.exp * 1000;
  } catch (e) {
    return true;
  }
};

export const isUserInAdminRole = () => {
  const userJson = localStorage.getItem('user');
  if(!userJson) return false;

  const user = JSON.parse(userJson);
  return !!user.admin;
};

export const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(
    (currentState: IAuthContextState, payload: IAuthProviderPayload) => {
      delete payload.data.user?.__typename;

      switch (payload.action) {
        case AuthActions.SETTOKEN:
          localStorage.setItem(StorageKeys.TOKEN, JSON.stringify(payload.data.token));
          return { ...currentState, token: payload.data.token };
        case AuthActions.SETUSER:
          localStorage.setItem(StorageKeys.USER, JSON.stringify(payload.data.user));
          return { ...currentState, user: payload.data.user };
        case AuthActions.LOGIN:
          localStorage.setItem(StorageKeys.TOKEN, JSON.stringify(payload.data.token));
          localStorage.setItem(StorageKeys.REFRESH, JSON.stringify(payload.data.refresh));
          localStorage.setItem(StorageKeys.USER, JSON.stringify(payload.data.user));
          return { ...currentState, user: payload.data.user, token: payload.data.token, loading: false };
        case AuthActions.LOGOUT:
          localStorage.clear();
          return { ...currentState, ...initialState, loading: false };
        case AuthActions.SETLOADING:
          return { ...currentState, loading: payload.data.loading };
        default:
          throw new Error('Undefined action provided.');
      }
    },
    initialState
  );

  const token = localStorage.getItem(StorageKeys.TOKEN);
  const refresh = localStorage.getItem(StorageKeys.REFRESH);
  const user = localStorage.getItem(StorageKeys.USER);

  useEffect(() => {
    if (token && user && refresh) {
      if(isTokenExpired(JSON.parse(token))) {
        refreshTokenRequest(JSON.parse(refresh))
          .then((res: any) => {
            if(!res.errors) {
              dispatch({ action: AuthActions.LOGIN, data: { token: res.jwtBearer, refresh: res.jwtRefresh, user: res.user }});
            } else {
              dispatch({ action: AuthActions.LOGOUT, data: {} });
            }
          })
      } else {
        dispatch({ action: AuthActions.LOGIN, data: { token: JSON.parse(token), user: JSON.parse(user), refresh: JSON.parse(refresh) }});
      }
    } else {
      dispatch({ action: AuthActions.LOGOUT, data: {} });
    }
  }, [token, refresh, user]);

  return (
    <AuthContext.Provider value={{ authState: state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};
