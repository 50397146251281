import { gql } from '@apollo/client';

export const NOTIFICATION_QUERY = gql`
  query Notification {
    notification {
      notifications {
        id
        type
        data
        read
        createdAt
        creator {
          id
          fullName
          avatar
        }
      }
      count
    }
  }
`;


export const NOTIFICATIONS_QUERY = gql`
  query Notifications {
    notifications {
      id
      type
      data
      read
      createdAt
      creator {
        id
        fullName
        avatar
      }
    }
  }
`;
