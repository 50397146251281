import { gql } from '@apollo/client';

export const LICENSES_QUERY = gql`
  query Licenses {
    licenses {
      id
      name
      state
      number
      type
      issueDate
      expireDate
      createdAt
    }
  }
`;
