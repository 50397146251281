import { gql } from '@apollo/client';

export const CREATE_LICENSE_MUTATION = gql`
	mutation CreateLicense {
		createLicense {
			id
      name
      state
      number
			type
      issueDate
      expireDate
			createdAt
		}
	}
`;