import { gql } from '@apollo/client';

export const DEAL_REPORT_QUERY = gql`
  query DealReport($input: DealReportInput) {
    dealReport(dealReportInput: $input) {
      closingdate
      address
      saleprice
      commission
      state
      type
      agentname
      officename
    }
  }
`;

export const REFERRED_REPORT_QUERY = gql`
  query ReferredReport {
    referredReport {
      agentname
      hiredate
      officename
      state
      referrername
    }
  }
`;

export const PERSONELLE_REPORT_QUERY = gql`
  query PersonnelReport {
    personnelReport {
      agentname
      licensename
      state
      number
      type
      issuedate
      expiredate
    }
  }
`;

export const PAYOUT_REPORT_QUERY = gql`
  query PayoutReport {
    payoutReport {
      agentname
      officename
      personal
      ssn
      business
      address
      state
      type
    }
  }
`;

export const ROSTER_REPORT_QUERY = gql`
  query RosterReport($input: RosterReportInput) {
    rosterReport(rosterReportInput: $input) {
      agentname
      email
      birthdate
      hiredate
      active
      officename
      state
    }
  }
`;