import { rosterReportRequest } from '@shared/services/report.service';
import { exportJsonToCsv, getStateName } from '@shared/utils/functions';
import { Button, message, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FaFileCsv } from 'react-icons/fa6';
import { MdOutlineQueryStats } from 'react-icons/md';

const columns = [
  { title: <span className='text-sm'>Agent Name</span>, width: 150, dataIndex: 'agentname', key: 'agentname', sorter: (a: any, b: any) => a.agentname.localeCompare(b.agentname) },
  { title: <span className='text-sm'>Email</span>, width: 150, dataIndex: 'email', key: 'email' },
  { title: <span className='text-sm'>Joined Date</span>, width: 80, dataIndex: 'hiredate', key: 'hiredate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-'  },
  { title: <span className='text-sm'>Birthday</span>, width: 80, dataIndex: 'birthdate', key: 'birthdate', render: (date: any) => date ? dayjs(date).format('MM/DD/YYYY') : '-'  },
  { title: <span className='text-sm'>Status</span>, width: 80, dataIndex: 'active', key: 'active', render: (value: boolean) => value ? 'Active' : 'Inactive'  },
  { title: <span className='text-sm'>Last Associated Office</span>, width: 150, dataIndex: 'officename', key: 'officename', sorter: (a: any, b: any) => a.officename?.localeCompare(b.officename) },
  { title: <span className='text-sm'>Associated State</span>, width: 130, dataIndex: 'state', key: 'state', render: (value: string) => getStateName(value) },
];

const Roster = () => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([1]);
  
  const _OnRunReport = () => {
    setFetching(true);

    rosterReportRequest({ status: selectedStatus })
      .then((res: any) => {
        setResults(res.response);
        setFetching(false);
      });
  };
  
    const _OnExportReport = () => {
      if(results.length > 0) {
        const csvData = results.map(({ __typename, agentname, email, hiredate, birthdate, active, ...rest }) => ({ agentname, email, hiredate: hiredate ? dayjs(hiredate).format('MM/DD/YYYY') : '', birthdate: birthdate ? dayjs(birthdate).format('MM/DD/YYYY') : '', active: active ? 'Active' : 'Inactive', ...rest }));
        exportJsonToCsv(csvData, `Agent_Roster_Report_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Agent Name', 'Email', 'Joined Date', 'Birthday', 'Status', 'Last Associated Office', 'Associated State']);
        messageApi.open({ type: 'success', content: 'Report data exported correctly' });
      } else {
        messageApi.open({ type: 'error', content: 'There are no records to export' });
      }
    };
  
    return (
      <>
        {contextHolder}
        <Content className='px-4 h-[97%]'>
          <div className='w-full grid grid-cols-12 grid-rows-2 gap-4'>
            <div className='col-span-4 flex items-center'>
              <div className='whitespace-nowrap mr-2'>Active Status: </div>
              <Select
                mode='multiple'
                showSearch
                optionFilterProp='label'
                className='w-full'
                value={selectedStatus}
                onChange={setSelectedStatus}
                options={[{ value: 1, label: 'Active' }, { value: 2, label: 'Inactive' }]}
              />
            </div>
            <div className='col-span-5'></div>
            <div className='col-span-3 flex items-center justify-end'>
              <Button className='rounded-xl' onClick={_OnRunReport} icon={<MdOutlineQueryStats size={20} className='mt-1' />}>Run Report</Button>
              <Button className='rounded-xl ml-2' onClick={_OnExportReport} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
            </div>
          </div>
          <div className='h-[calc(100vh-280px)] mt-[-30px]'>
            <Table
              columns={columns}
              loading={fetching}
              size='small'
              pagination={{ 
                pageSizeOptions: ['25', '50', '100'], 
                defaultPageSize: 50,
                total: results.length,
                showTotal: (total) => `${total} Results`,
              }}
              scroll={{ x: 'max-content', y: 'calc(100vh - 320px)' }}
              rowClassName={() => 'font-row-sm'}
              dataSource={results}
            />
          </div>
        </Content>
      </>
  );
};

export default Roster;