import { Tooltip } from 'antd';
import { useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const MaskedCell = ({ value }: any) => {
  const [visible, setVisible] = useState(false);

  if (!value) return <span>-</span>;

  const maskValue = (val: string) => `XXX-XX-${val.slice(-4)}`;

  return (
    <div className='flex items-center justify-between'>
      <span>{visible ? value : maskValue(value)}</span>
      <Tooltip title={visible ? 'Hide' : 'Show'}>
        {visible ? (
          <IoEyeOffOutline className='ml-2 cursor-pointer' onClick={() => setVisible(!visible)} style={{ fontSize: '16px' }}/>
        ) : (
          <IoEyeOutline className='ml-2 cursor-pointer' onClick={() => setVisible(!visible)} style={{ fontSize: '16px' }}/> 
        )}
      </Tooltip>
    </div>
  );
};

export default MaskedCell;