import gql from 'graphql-tag';

export const REFRESH_TOKEN_MUTATION = gql`
	mutation RefreshToken($input: RefreshTokenInput!) {
		refreshToken(input: $input) {
      jwtBearer
      jwtRefresh
      user {
        id
        fullName
        email
        password
        admin
        birthDate
        hireDate
        jobTitle
        ssn
			  personal
        officeId
        role
        bio
        phone
        fax
        emergency
        avatar
        social
        state
      }
		}
	}
`;
