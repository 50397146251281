import apolloClient from '@config/apolloClient';
import { CLICKED_TEMPLATE_MUTATION } from '@mutations/template/clicked.gpl';
import { CREATE_TEMPLATE_MUTATION } from '@mutations/template/create.gpl';
import { DELETE_TEMPLATE_MUTATION } from '@mutations/template/delete.gpl';
import { TEMPLATES_QUERY } from '@queries/templates.gpl';

export const findTemplatesRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: TEMPLATES_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.templates };
};

export const createTemplateRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_TEMPLATE_MUTATION,
    variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createTemplate };
};

export const deleteTemplateRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_TEMPLATE_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeTemplate };
};

export const clickedTemplateRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CLICKED_TEMPLATE_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.clickedTemplate };
};