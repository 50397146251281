import apolloClient from '@config/apolloClient';
import { IBusiness } from '@interfaces/business.interface';
import { UPDATE_BUSINESS_MUTATION } from '@mutations/business/update.gpl';
import { BUSINESS_QUERY } from '@queries/business.gpl';

export const findBusinessRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: BUSINESS_QUERY
	});

	if (errors) return { errors };

	return { response: data.business };
};

export const updateBusinessRequest = async (payload: IBusiness) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_BUSINESS_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return data.updateBusiness;
};