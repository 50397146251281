import { useEffect } from 'react';

const TockifyCalendar = ({ mode }: any) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://public.tockify.com/browser/embed.js';
    script.async = true;
    script.setAttribute('data-tockify-script', 'embed');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div data-tockify-component={mode} data-tockify-calendar='distinctionre'></div>
  );
}

export default TockifyCalendar;