import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from '@firebase/messaging';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const getFirebaseDeviceToken = async () => {
	try {
		if (Notification.permission === 'granted') {
			return getToken(firebaseMessaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID });
		}
	
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			return getToken(firebaseMessaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID });
		} else {
			return null;
		}
	} catch (err) {
		console.log(err)
		return null;
	}
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseMessaging = getMessaging(firebaseApp);