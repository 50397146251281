/* eslint-disable no-whitespace-before-property */
/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined } from '@ant-design/icons';
import EditableTable from '@components/EditableTable';
import { IDocument } from '@interfaces/document.interface';
import { ILicense } from '@interfaces/license.interface';
import { AuthActions, AuthContext } from '@shared/context/auth';
import { findBusinessRequest, updateBusinessRequest } from '@shared/services/business.service';
import { createDocumentRequest, deleteDocumentRequest, findDocumentsRequest } from '@shared/services/document.service';
import { createLicenseRequest, deleteLicenseRequest, findLicensesRequest, updateLicenseRequest } from '@shared/services/license.service';
import { BusinessTypes, LicenseTypes, States } from '@shared/utils/enums';
import { formatFileSize, getBusinessTypesText, getLicenseTypesText, getStatesFromLicenses, uploadFile } from '@shared/utils/functions';
import { Button, Divider, Form, Input, message, Popconfirm, Select, Table, TableProps, Typography, Upload } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { FiDownloadCloud, FiSave } from 'react-icons/fi';
import { MdOutlineIosShare } from 'react-icons/md';

const { Title } = Typography;

const columns = [
  { title: 'Name on License', dataIndex: 'name', width: '18%', editable: true, onHeaderCell: () => ({ style: { fontSize: 12 }} )},
  { title: 'State', dataIndex: 'state', width: '14%', editable: true, inputType: 'select', options: Object.entries(States).map(([label, value]) => ({ label, value })), onHeaderCell: () => ({ style: { fontSize: 12 }} )},
  { title: 'License #', dataIndex: 'number', width: '14%', editable: true, onHeaderCell: () => ({ style: { fontSize: 12 }} )},
  { title: 'License Type', dataIndex: 'type', width: '14%', editable: true, inputType: 'select', options: Object.entries(LicenseTypes).map(([label, value]) => ({ value, label: getLicenseTypesText(value) })), onHeaderCell: () => ({ style: { fontSize: 12 }} )},
  { title: 'Issue Date', dataIndex: 'issueDate', width: '16%', inputType: 'date', editable: true, onHeaderCell: () => ({ style: { fontSize: 12 }} )},
  { title: 'Expiration Date', dataIndex: 'expireDate', width: '16%', inputType: 'date', editable: true, onHeaderCell: () => ({ style: { fontSize: 12 }} )},
];

const Documents = () => {
  const { authState, dispatch } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [licenses, setLicenses] = useState<ILicense[]>([]);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [formData, setFormData] = useState<any>();
  const [selected, setSelected] = useState<any>(null);

  const [form] = Form.useForm();
  const columnsD: TableProps<IDocument>['columns'] = [
    { title: <span className='text-xs'>File Name</span>, width: 280, dataIndex: 'name', key: 'name' },
    { title: <span className='text-xs'>Type</span>, width: 80, dataIndex: 'extension', key: 'extension' },
    { title: <span className='text-xs'>Size</span>, width: 120, dataIndex: 'size', key: 'size', render: (value) => formatFileSize(value)},
    { title: <span className='text-xs'>Last Modified</span>, width: 180, dataIndex: 'modifyDate', key: 'modifyDate', render: (date) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
    { title: <span className='text-xs'>Upload Date</span>, width: 180, dataIndex: 'createdAt', key: 'createdAt', render: (date) => date ? dayjs(date).format('MM/DD/YYYY') : '-' },
    {
      title: <span className='text-xs'>Actions</span>, width: 60,
      fixed: 'right',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
          return <span className='flex flex-row'>
            <MdOutlineIosShare onClick={() => share(record.url)} className='ml-4 mr-4 cursor-pointer' title='Share'/>
            <a className='hover:no-underline hover:text-inherit' href={record.url} download='Download' target='_blank' rel='noreferrer'>
              <FiDownloadCloud className='mr-4 cursor-pointer' title='Download'/>
            </a>
            <Popconfirm title={`Delete this Document?`} icon={null} okText='Yes' cancelText='No' onConfirm={() => _DeleteDocument(record.id)}>
              <DeleteOutlined title='Delete' className='mr-4'/>
            </Popconfirm>
          </span>
      },
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const result = await findBusinessRequest();
      setFormData({ ...result.response });
      form.setFieldsValue({ ...result.response });

      const res = await findLicensesRequest();
      setLicenses(res.response.map((license: any) => ({ ...license, issueDate: license.issueDate ? dayjs(license.issueDate) : null, expireDate: license.expireDate ? dayjs(license.expireDate) : null})));

      findDocumentsRequest()
        .then((res) => {
          setFetching(false);
          setDocuments(res.response);
        })
    };

    fetchData();
  }, []); 

  const _onSaveChanges = async () => {
    try {
      const values = await form.validateFields();
  
      setSaving(true);
      delete formData.__typename;

      updateBusinessRequest({ ...formData, ...values })
        .then(() => {
          setSaving(false);
          messageApi.open({ type: 'success', content: 'MLS Information updated correctly' });
        })
        .catch(() => {
          setSaving(false);
          messageApi.open({ type: 'error', content: 'Failed to update information' });
        });
    } catch (err) {
      messageApi.open({ type: 'error', content: 'Please, complete mandatory fields' });
    }
  };

  const _onChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
  };

  const _AddLicense = () => {
    createLicenseRequest()
      .then((resp: any) => {
        const newData: ILicense = { id: resp.response.id, name: undefined, state: undefined, address: undefined, type: undefined, issueDate: undefined, expireDate: undefined, createdAt: new Date() };
        setLicenses([newData, ...licenses]);
        setSelected(newData);
      })
  };

  const _UpdateLicense = async (row: any) => {
    const cloned = [...licenses];
    const index = cloned.findIndex((item) => row.id === item.id);
    
    if (index > -1) {
      const item = cloned[index];
      cloned.splice(index, 1, { ...item, ...row });
      setLicenses([...cloned]);
    }

    await updateLicenseRequest(row);
    dispatch({ action: AuthActions.SETUSER, data: { user: {...authState.user, state: getStatesFromLicenses(cloned) }} });
  };

  const _DeleteLicense = async (id: number) => {
    const cloned = [...licenses];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setLicenses([...cloned]);
    }

    await deleteLicenseRequest(id);
    dispatch({ action: AuthActions.SETUSER, data: { user: {...authState.user, state: getStatesFromLicenses(cloned) }} });
  };

  const _onFileChange = async ({ file }: any) => {
    setUploading(true);
    const upload = await uploadFile(file, 'file', 'document');

    createDocumentRequest({ ownerId: authState.user.id, url: upload.url, modifyDate: file.lastModifiedDate, name: file.name.slice(0, file.name.lastIndexOf('.')), extension: file.name.slice(file.name.lastIndexOf('.') + 1), size: file.size })
      .then(async (res) => { 
        setDocuments([ ...documents, res.response ]);
        setUploading(false);
        messageApi.open({ type: 'success', content: 'Document uploaded correctly' });
      });
  };

  const _DeleteDocument = async (id: number) => {
    const cloned = [...documents];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setDocuments([...cloned]);
    }

    await deleteDocumentRequest(id);
    messageApi.open({ type: 'success', content: 'Document deleted correctly' });
  };

  const share = async (url: string) => {
    await navigator.clipboard.writeText(url);
    messageApi.open({ type: 'success', content: 'Download link copied to clipboard' });
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8'>
        <div className='flex justify-between'>
          <Title level={5} className='mt-[-20px] mb-[-20px]'>MLS Information</Title>
          <Button className='rounded-xl mt-[-30px] mb-[-20px]' loading={saving} onClick={_onSaveChanges} icon={<FiSave size={20} className='mt-1' />}>Save Changes</Button>
        </div>
        <Divider className='mt-0' />
        <Form form={form} layout='vertical'>
          <div className='flex rounded-md border-gray-100 border-2 w-min p-2 mt-[-10px] mb-4'>
            <div className='flex flex-col items-center mx-2 w-32'>
              <span className='text-xs whitespace-nowrap'>MLS Public ID</span>
              <span className='text-lg mt-2'>{formData?.mlsId}</span>
            </div>
            <div className='flex flex-col items-center mx-2 w-32'>
              <span className='text-xs whitespace-nowrap'>MLS Name</span>
              <span className='text-lg mt-2'>{formData?.mlsName}</span>
            </div>
            <div className='flex flex-col items-center mx-2 w-32'>
              <span className='text-xs whitespace-nowrap'>NARS</span>
              <Input className='w-full mt-2 text-center' value={formData?.realtorId} onChange={(e) => _onChange('realtorId', e.target.value)} />
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <Title level={5}>Licenses</Title>
            <Button className='rounded-xl mt-[-8px]' onClick={_AddLicense} type='primary'>Add License</Button>
          </div>
          <div className='w-full'>
            <EditableTable 
              source={licenses} 
              columns={columns}
              loading={fetching}
              selection={selected}
              name='License' 
              updateFn={_UpdateLicense}
              deleteFn={_DeleteLicense} 
            />
          </div>
          <Title level={5} className='mt-4 mb-[-20px]'>W9</Title>
          <Divider className='mt-0' />
          <div className='w-full grid grid-cols-12 gap-4 mb-8 mt-[-10px]'>
            <div className='col-span-2'>
              <div className='text-xs'>W9 Name</div>
              <Form.Item name='name' rules={[{ required: true, message: 'W9 Name is mandatory' }]}>
                <Input className='w-full' onChange={(e) => { _onChange('name', e.target.value); form.setFieldValue('name', e.target.value); }} />
              </Form.Item>
            </div>
            <div className='col-span-3'>
              <div className='text-xs'>W9 Business Name</div>
              <Form.Item name='business' rules={[{ required: true, message: 'W9 Business Name is mandatory' }]}>
                <Input className='w-full' onChange={(e) => { _onChange('business', e.target.value); form.setFieldValue('business', e.target.value); }} />
              </Form.Item>
            </div>
            <div className='col-span-3'>
              <div className='text-xs'>W9 Address</div>
              <Form.Item name='address' rules={[{ required: true, message: 'W9 Address is mandatory' }]}>
                <Input className='w-full' onChange={(e) => { _onChange('address', e.target.value); form.setFieldValue('address', e.target.value); }} />
              </Form.Item>
            </div>
            <div className='col-span-2'>
              <div className='text-xs'>W9 State</div>
              <Form.Item name='state' rules={[{ required: true, message: 'W9 State is mandatory' }]}>
                <Select
                  showSearch
                  placeholder='Select a State'
                  optionFilterProp='label'
                  className='w-full'      
                  options={Object.entries(States).filter(([label, value]) => value !== 'NV').map(([label, value]) => ({ label, value }))}
                  onChange={(e) => { _onChange('state', e); form.setFieldValue('state', e); }}
                />
              </Form.Item>
            </div>
            <div className='col-span-2'>
              <div className='text-xs'>W9 Type</div>
              <Form.Item name='type' rules={[{ required: true, message: 'W9 Type is mandatory' }]}>
                <Select
                  showSearch
                  placeholder='Select a Type'
                  optionFilterProp='label'
                  className='w-full'
                  options={Object.entries(BusinessTypes).map(([label, value]) => ({ value, label: getBusinessTypesText(value) }))}
                  onChange={(e) => { _onChange('type', e); form.setFieldValue('type', e); }}
                />
              </Form.Item>
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <Title level={5}>Documents</Title>
            <Upload showUploadList={false} customRequest={_onFileChange}>
              <Button className='rounded-xl mt-[-16px]' loading={uploading} type='primary'>Add Document</Button>
            </Upload>
          </div>
          <div className='w-full'>
            <Table<IDocument>
              columns={columnsD}
              loading={fetching}
              size='small'
              pagination={false}
              dataSource={documents}
              scroll={{ x: 'max-content' }}
            />
          </div>
        </Form>

      </Content>
    </>
  );
}

export default Documents;