
import { gql } from '@apollo/client';

export const DASHBOARD_QUERY = gql`
  query Dashboard {
    dashboard {
      birthdays {
        id
        fullName
        birthDate
      }
      anniversaries {
        id
        fullName
        hireDate
      }
      hires {
        id
        fullName
        verifyDate
      }
      posts {
        id
        title
        body
      }
      volume
      transactions
      locations
      professionals
      topProducing {
        agent
        earnings
      }
      topReferrer {
        agent
        count
      }
    }
  }
`;

export const TOP_AGENTS_QUERY = gql`
  query TopAgents($period: String!) {
    topAgents(period: $period) {
      agent
      earnings
    }
  }
`;