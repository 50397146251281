import { Avatar, Button, Checkbox, DatePicker, Image, Input, message, Popconfirm, Spin } from 'antd';
import { IoChatbubbleSharp } from 'react-icons/io5';
import { IoMdSave } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import { FiEdit3 } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';
import { UserOutlined } from '@ant-design/icons';
import { formatDatePost } from '@shared/utils/functions';
import { forwardRef, useContext, useRef, useState } from 'react';
import { createCommentRequest, deleteCommentRequest, updateCommentRequest } from '@shared/services/comment.service';
import { AuthContext, isUserInAdminRole } from '@shared/context/auth';
import { deletePostRequest, togglePostRequest, updatePostRequest } from '@shared/services/post.service';
import { IComment } from '@interfaces/comment.interface';
import dayjs from 'dayjs';

const { TextArea } = Input;

const ForwardedImage = forwardRef((props: any, ref: any) => (
  <div ref={ref}>
    <Image {...props} />
  </div>
));

const PostGallery = ({ images }: any) => {
  const previewRef = useRef<any>();
  
  if (images.length === 0) return null;

  const extraCount = images.length - 4;

  const _onMorePreview = () => {
    if (previewRef.current) {
      const img = previewRef.current.querySelector('img');
      if (img) img.click();
    }
  };

  return (
    <div className='self-center w-full mb-4'>
      {images.length === 1 && (
        <div className='flex items-center justify-center w-full'>
          <Image src={images[0].url} width={'50%'} height={264} className='rounded-lg object-cover self-center' />
        </div>
      )}

      {images.length === 2 && (
        <div className='flex items-center justify-center w-full'>
          <Image src={images[0].url} width={'50%'} height={264} className='h-auto rounded-lg object-cover self-center' />
          <Image src={images[1].url} width={'50%'} height={264} className='h-auto rounded-lg object-cover self-center ml-2' />
        </div>
      )}

      {images.length === 3 && (
        <div className='flex items-center justify-center w-full'>
          <Image src={images[0].url} width={'60%'} height={264} className='h-full rounded-lg object-cover self-center' />
          <div className='w-[40%] ml-2'>
            <Image src={images[1].url} width={'100%'} height={129} className='h-auto rounded-lg object-cover self-center' />
            <Image src={images[2].url} width={'100%'} height={129} className='h-auto rounded-lg object-cover self-center mt-1' />
          </div>
        </div>
      )}

      {images.length === 4 && (
        <div className='flex items-start justify-center w-full'>
          <Image src={images[0].url} width={'50%'} height={265} className='rounded-lg object-cover self-center' />
          <div className='flex flex-col w-[50%] ml-2'>
            <div className='w-full'>
              <Image src={images[1].url} width={'100%'} height={129} className='rounded-lg object-cover self-center' />
            </div>
            <div className='w-full flex'>
              <Image src={images[2].url} width={'49%'} height={129} className='rounded-lg object-cover self-center mt-1' />
              <Image src={images[3].url} width={'49%'} height={129} className='rounded-lg object-cover self-start ml-2 mt-1' />
            </div>
          </div>

        </div>
      )}

      {images.length > 4 && (
        <div className='flex items-start justify-center w-full'>
          <Image src={images[0].url} width={'50%'} height={265} className='rounded-lg object-cover self-center' />
          <div className='w-1/4 ml-2'>
            <Image src={images[1].url} width={'100%'} height={129} className='rounded-lg object-cover self-center' />
            <Image src={images[2].url} width={'100%'} height={129} className='rounded-lg object-cover self-center mt-1' />
          </div>
          <div className='w-1/4 ml-2'>
            <Image src={images[3].url} width={'100%'} height={129} className='rounded-lg object-cover self-center' />
            <div className='h-auto self-center relative'>
              <Image.PreviewGroup>
                <ForwardedImage src={images[4].url} width={'100%'} height={129} className='rounded-lg object-cover mt-1' ref={previewRef}/>
                {extraCount > 0 && (
                    <div title='See more images' className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg mt-1 cursor-pointer' onClick={_onMorePreview}>
                      <span className='text-white text-3xl font-semibold'>
                        +{extraCount}
                      </span>
                      {images.slice(5).map((image: any, index: number) => (
                        <Image 
                          key={index}
                          src={image.url}
                          style={{ display: 'none' }}
                        />
                      ))}
                    </div>
                  )}
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PostHeader = ({ post, replies, isComment, isEditing, isSaving, canSave, toggleComments, toggleEditing, saveChanges, deletePost, cancelChanges }: any) => {
  const { authState } = useContext(AuthContext);

  return (
    <div className='w-full flex justify-between mt-2 mb-2'>
      <div className='flex justify-center items-center'>
        <Avatar src={post.author.avatar} icon={<UserOutlined />} size='small' className='self-center bg-distinctive-text' />
        <span className='font-bold ml-2'>{post.author.fullName}</span>
      </div>
      <div className='flex justify-center items-center'>
        {post.author.id === authState.user.id && 
          (isEditing ? 
            (isSaving ? 
              <Spin size='small' className='mr-1' /> :
              <IoMdSave title='Save changes' className={`cursor-pointer mr-1 text-distinctive-gold ${canSave ? '' : 'opacity-40'}`} onClick={() => { if(canSave) saveChanges() }}/>
            ) : 
            <FiEdit3 title='Edit comment' className='cursor-pointer mr-2 text-distinctive-gold' size={12} onClick={() => toggleEditing(true)} />
          )
        }
        {post.author.id === authState.user.id && 
          (isEditing ? 
            <MdCancel title='Discard changes' className={`${isSaving ? 'opacity-50' : 'cursor-pointer'} text-distinctive-gold`} onClick={cancelChanges}/> : 
            <Popconfirm title={`Delete this ${isComment ? 'comment' : 'post'}?`} icon={null} okText='Yes' cancelText='No' onConfirm={deletePost}>
              <FaTrashAlt title='Delete comment' className='cursor-pointer text-distinctive-gold' size={10}/>
            </Popconfirm>
          )
        }
        <span className='text-xs ml-2'>{formatDatePost(new Date(post.createdAt))}</span>
        {!isComment &&
          <div className='ml-2 flex justify-center items-center text-distinctive-gold cursor-pointer' title='View comments' onClick={toggleComments}>
            <IoChatbubbleSharp />
            <span className='text-xs ml-1'>{replies}</span>
          </div>
        }
      </div>
    </div>
  );
}

const PostContent = ({ post, counter, isComment, deleteComment, toggleComments }: any) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [postOriginalBody, setPostOriginalBody] = useState(post.body);
  const [postTitle, setPostTitle] = useState(post.title);
  const [postBody, setPostBody] = useState(post.body);
  const [showUntil, setShowntil] = useState(post.expireDate);

  const _onSaveChanges = () => {
    setIsSaving(true);
    
    if(isComment) {
      updateCommentRequest({ id: post.id, body: postBody })
        .then(() => {
          setIsSaving(false);
          setIsEditing(false);
          setPostOriginalBody(postBody);
          messageApi.open({ type: 'success', content: 'Comment updated successfully' });
        })
    } else {
      updatePostRequest({ id: post.id, title: postTitle, body: postBody, expireDate: showUntil, attachments: post.attachments })
        .then(() => {
          setIsSaving(false);
          setIsEditing(false);
          setPostOriginalBody(postBody);
          messageApi.open({ type: 'success', content: 'Board post updated successfully' });
        })
    }
  };

  const _onDeletePost = () => {
    if(isComment) {
      deleteCommentRequest(post.id)
        .then(() => {
          deleteComment(post.id, true);
        })
    } else {
      deletePostRequest(post.id)
        .then(() => {
          deleteComment(post.id, false, post.attachments);
        })
    }
  };

  const _onDiscardChanges = () => {
    if(isSaving) return;
    
    setPostBody(postOriginalBody);
    setIsEditing(false);
  };

  return (
    <>
      {contextHolder}
      <div className='w-full relative'>
        <PostHeader 
          post={post} 
          replies={counter}
          isComment={isComment} 
          isEditing={isEditing}
          isSaving={isSaving}
          canSave={isComment ? !!postBody : (!!postBody && !!postTitle)}
          toggleComments={toggleComments}
          toggleEditing={setIsEditing}
          saveChanges={_onSaveChanges}
          deletePost={_onDeletePost}
          cancelChanges={_onDiscardChanges}
        />
        {!isComment &&  
          <div className='w-full'>
            {(!isEditing ?
              <div className='flex w-full justify-between'>
                <span className='text-xl font-bold my-2'>{postTitle}</span>
              </div> : 
              <div className='flex w-full justify-between items-center'>
                <div className='w-full'>
                  <span className='text-xs font-bold'>Post Title:</span>
                  <Input 
                    status={postTitle ? '': 'error'}
                    className='my-2'
                    placeholder='Title cannot be empty'
                    value={postTitle}
                    onChange={(e) => setPostTitle(e.target.value)}
                  />
                </div>
                <div className='w-1/3 ml-2'>
                  <span className='text-xs font-bold'>Show in Announcements Until:</span>
                  <DatePicker 
                    className='my-2 w-full' 
                    value={showUntil ? dayjs(showUntil) : null}
                    onChange={(e) => setShowntil(e ? e.toDate() : null)}
                  />
                </div>
              </div>
            )}
            <PostGallery images={post.attachments} />
          </div> 
        }
        {isEditing ? 
          (isComment ? 
            <Input 
              status={postBody ? '': 'error'} 
              placeholder='Comment cannot be empty'
              value={postBody}
              onChange={(e) => setPostBody(e.target.value)}
            /> :
            <TextArea
              className='w-full my-1'
              status={postBody ? '': 'error'} 
              placeholder='Post content cannot be empty'
              rows={1}
              value={postBody}
              onChange={(e) => setPostBody(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
              style={{ resize: 'none' }}
            />) : 
          <span className='flex text-justify'>{postBody}</span>
        }
      </div>
    </>
  );
};

const BoardPost = ({ post, deletePost, togglePost, viewComments = false }: any) => {
  const { authState } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  
  const [saving, setSaving] = useState(false);
  const [counter, setCounter] = useState(post.comments.length);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<IComment[]>([...post.comments].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
  const [isCommenting, setIsCommenting] = useState(false);
  const [showComments, setShowComments] = useState(viewComments);
  
  const _onToggleComments = () => {
    setShowComments(!showComments);
  };

  const _onTogglePost = () => {
    setSaving(true);

    togglePostRequest(post.id)
      .then(() => {
        togglePost(post.id, !post.dashboard);
        setSaving(false);
      })
  };

  const _onCommentPost = () => {
    setIsCommenting(true);

    createCommentRequest({ postId: post.id, authorId: authState.user.id, body: comment })
      .then((res) => {
        setComment('');
        setComments([...comments, { id: res.response.id, body: comment, createdAt: res.response.createdAt, author: { id: authState.user.id, fullName: authState.user.fullName, avatar: authState.user.avatar }}]);
        setCounter(counter + 1);
        setIsCommenting(false);

        messageApi.open({ type: 'success', content: 'Comment created successfully' });
      })
  };

  const _onDeleteComment = (id: number, isComment: boolean, attachments: any[]) => {
    if(isComment) {
      setCounter(counter - 1);
      setComments([ ...comments.filter((comment: any) => comment.id !== id)]);

      messageApi.open({ type: 'success', content: 'Comment deleted successfully' });
    } else {
      deletePost(id, attachments);
    }
  };

  return (
    <>
      {contextHolder}
      <div className='bg-white px-4 py-2 rounded-lg w-3/4 self-center mb-6 flex flex-col items-center'>
        <PostContent post={post} isComment={false} counter={counter} toggleComments={_onToggleComments} deleteComment={_onDeleteComment}/>
        {showComments && comments.map((comment: any) => {
          return <div className='w-3/4 mt-2' key={comment.id}>
              <PostContent key={comment.id} post={comment} isComment={true} deleteComment={_onDeleteComment}/>
            </div>
        })}
        {showComments && 
          <div className='flex items-center border-distinctive-gold border rounded-xl w-3/4 my-4 self-center'>
            <TextArea
              variant='borderless'
              className='w-full my-1'
              placeholder='Enter your reply'
              rows={1}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
              style={{ resize: 'none' }}
            />
            <Button loading={isCommenting} className='m-2' onClick={_onCommentPost}>POST</Button>
          </div>
        }
        {isUserInAdminRole() && 
          <div className='w-full flex justify-end items-center mt-4 mb-2'>
            <span>Push to Announcements</span>
            {saving ? <Spin size='small' className='ml-2' /> : <Checkbox className='ml-2' checked={post.dashboard} onChange={_onTogglePost}/>}
          </div>
        }
      </div>
    </>
  );
};

export default BoardPost;