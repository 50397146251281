import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_MUTATION = gql`
	mutation UpdateBusiness($input: UpdateBusinessInput!) {
		updateBusiness(updateBusinessInput: $input) {
			realtorId
			name
			business
			address
			state
			type
		}
	}
`;