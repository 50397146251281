/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, message, Popconfirm } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useMemo, useState } from 'react';
import EditableTable from '@components/EditableTable';
import ResetPassword from '@assets/icons/reset-password.svg';
import { createUserRequest, deleteUserRequest, findUsersRequest, refreshUserRequest, userOptionRequest } from '@shared/services/user.service';
import { IUser } from '@interfaces/user.interface';
import { forgotPasswordRequest } from '@shared/services/auth.service';
import { Role } from '@shared/utils/enums';
import dayjs from 'dayjs';
import { exportJsonToCsv, formatAsPhone, sortUsersByLastName } from '@shared/utils/functions';
import { FaFileCsv } from 'react-icons/fa6';
import { IOffice } from '@interfaces/office.interface';

const User = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = useState<any>([]);

  useMemo(() => {
    const fetchColumns = async () => {
      const options = await userOptionRequest();

      setColumns([
        { title: 'Name', dataIndex: 'fullName', width: 200, editable: true, fixed: true, defaultSortOrder: 'descend', sorter: (a: any, b: any) => a.fullName.localeCompare(b.fullName) },
        { title: 'Role', dataIndex: 'role', width: 140, editable: true, inputType: 'select', options: Object.values(Role).map((val) => ({ value: val, label: val })) },
        { title: 'Email', dataIndex: 'email', width: 320, editable: true, validationRules: [{ required: true, type: 'email', message: 'Invalid email format'}] },
        { title: 'Phone', dataIndex: 'phone', width: 180, editable: true, inputType: 'phone', render: (value: string) => formatAsPhone(value) },
        { title: 'Office', dataIndex: 'officeId', width: 250, editable: true, inputType: 'select', options: options.response.offices.map((office: any) => ({ value: office.id, label: office.name })) },
        { title: 'Status', width: 90, dataIndex: 'active', key: 'active', render: (value: boolean) => value ? 'Active' : 'Inactive'  },
        { title: 'Hire Date', dataIndex: 'hireDate', width: 160, editable: true, inputType: 'date' },
        { title: 'Referred By', dataIndex: 'referrerId', width: 200, editable: true, inputType: 'select', options: options.response.users.map((user: any) => ({ value: user.id, label: user.fullName })) },
        { title: 'Admin', dataIndex: 'admin', width: 90, editable: true, inputType: 'bool' },
      ]);
      setOffices(options.response.offices);
    };

    setLoading(true);
    fetchColumns();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await findUsersRequest({});
      setUsers(sortUsersByLastName(result.response).map((user: any) => ({ ...user, hireDate: user.hireDate ? dayjs(user.hireDate) : null })));
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, []); 

  const actions = (record: any) => (
    <Popconfirm title='Send Password Reset Email?' icon={null} okText='Yes' cancelText='No' onConfirm={() => { _ForgotPasswordLink(record.email) }}>
      <img src={ResetPassword} className='mr-4 h-[18px] cursor-pointer' title='Reset Password' alt='Password' />
    </Popconfirm>
  );
  
  const _ForgotPasswordLink = async (email: string) => {
    await forgotPasswordRequest(email)
      .then((resp: any) => {
        messageApi.open({ type: resp.response.type, content: `An email with reset instructions was sent to ${email}` });
      })
  }

  const _FilterUser = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const _AddUser = () => {
    createUserRequest({ fullName: ''})
      .then((resp) => {
        const newData: IUser = { id: resp.response.id, fullName: undefined, phone: undefined, email: undefined, officeId: undefined, admin: undefined };
        setUsers([newData, ...users]);
        setSelected(newData);
      })
      .catch((err) => {
        console.log('🚀 ~ err', err);
      });
  };

  const _UpdateUser = async(row: any) => {
    const cloned = [...users];
    const index = cloned.findIndex((item) => row.id === item.id);
    
    if (index > -1) {
      const item = cloned[index];
      cloned.splice(index, 1, { ...item, ...row });
      setUsers([...cloned]);
    }

    await refreshUserRequest(row);
  };

  const _DeleteUser = async (id: number) => {
    const cloned = [...users];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setUsers([...cloned]);
    }

    await deleteUserRequest(id);
  };

  const _ExportUsers = () => {
    const csvData = users.map(({ __typename, id, officeId, active, hireDate, referrerId, admin, avatar, ...rest }) => ({ 
      ...rest, 
      officeId: offices.find((office: any) => office.id === officeId)?.name || '', 
      active: active ? 'Active' : 'Inactive',
      hireDate: hireDate ? dayjs(hireDate).format('MM/DD/YYYY') : '', 
      referrerId: users.find((user: any) => user.id === referrerId)?.fullName || '', 
      admin: admin ? 'Yes' : 'No'
    }));

    exportJsonToCsv(csvData, `Admin_Users_${new Date().toISOString().replace(/[-:.TZ]/g, '')}`, ['Name', 'Role', 'Email', 'Phone', 'Office', 'Status', 'Hire Date', 'Referred By', 'Admin']);
    messageApi.open({ type: 'success', content: 'User list exported correctly' });
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8 flex flex-col'>
        <div className='mt-[-20px] flex justify-between mb-4'>
          <Input placeholder='Search' className='rounded-xl w-[25%]' onChange={_FilterUser}></Input>
          <div>
            <Button className='rounded-xl' onClick={_ExportUsers} icon={<FaFileCsv size={16} className='mt-1' />}>Export</Button>
            <Button className='rounded-xl ml-2' onClick={_AddUser} type='primary'>Add User</Button>
          </div>
        </div>
        <div className='h-[calc(100vh-320px)]'>
          <EditableTable
            source={users.filter((item) => !item.fullName || item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) )}
            columns={columns}
            actions={actions}
            loading={loading}
            selection={selected}
            name='User'
            updateFn={_UpdateUser}
            deleteFn={_DeleteUser}
            scrollYValue='calc(100vh - 390px)'
          />
        </div>
      </Content>
    </>
  );
};

export default User;