import { Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Deals from './Deals';
import Referred from './Referred';
import Payout from './Payout';
import Roster from './Roster';
import Personnel from './Personnel';
import { isUserInAdminRole } from '@shared/context/auth';

const { Title } = Typography;

const Report = () => {
  const tabs = isUserInAdminRole() ? [
    { label: `Agent Production Report`, key: '1', children: <Deals />},
    { label: `Agent Referred Report`, key: '2', children: <Referred />},
    { label: `Agent Licensing Report`, key: '3', children: <Personnel />},
    { label: `Pay Out Report`, key: '4', children: <Payout />},
    { label: `Agent Roster Report`, key: '5', children: <Roster />}
  ] : [
    { label: `Agent Production Report`, key: '1', children: <Deals />},
    { label: `Agents Referred Report`, key: '2', children: <Referred />}
  ];

  return (
    <Content className='bg-[#F9F5F0] rounded-lg p-4 m-2 flex flex-col h-[97%]'>
      <Title level={5} className='ml-4'>Reports</Title>
      <Tabs type='card' className='bg-white mt-4 mt-4 flex-grow overflow-hidden' items={tabs} />
    </Content>
  );
};

export default Report;
