import { gql } from '@apollo/client';

export const UPDATE_LICENSE_MUTATION = gql`
	mutation UpdateLicense($input: UpdateLicenseInput!) {
		updateLicense(updateLicenseInput: $input) {
      id
      name
      state
      number
			type
      issueDate
      expireDate
      createdAt
		}
	}
`;