import apolloClient from '@config/apolloClient';
import { CREATE_LICENSE_MUTATION } from '@mutations/license/create.gpl';
import { DELETE_LICENSE_MUTATION } from '@mutations/license/delete.gpl';
import { UPDATE_LICENSE_MUTATION } from '@mutations/license/update.gpl';
import { LICENSES_QUERY } from '@queries/licenses.gpl';

export const findLicensesRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: LICENSES_QUERY
	});

	if (errors) return { errors };

	return { response: data.licenses };
};

export const createLicenseRequest = async () => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_LICENSE_MUTATION
	});

	if (errors) return { errors };

	return { response: data.createLicense };
};

export const updateLicenseRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_LICENSE_MUTATION,
    variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.updateLicense };
};

export const deleteLicenseRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_LICENSE_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeLicense };
};