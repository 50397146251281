import { Content } from 'antd/es/layout/layout';
import DashboardStat from '@components/DashboardStat';
import { Button, Card, Input, Select } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatAsCurrency } from '@shared/utils/functions';
import TockifyCalendar from '@components/TockifyCalendar';
import { isUserInAdminRole } from '@shared/context/auth';
import { dashboardRequest, topAgentsRequest } from '@shared/services/dashboard.service';

const Dashboard = () => {
	const navigate = useNavigate();
	
	const [hires, setHires] = useState<any[]>([]);
	const [dates, setDates] = useState<any[]>([]);
	const [topAgents, setTopAgents] = useState<any[]>([]);
	const [topReffers, setTopReffers] = useState<any[]>([]);
	const [newPosts, setNewPosts] = useState<any[]>([]);
	const [volume, setVolume] = useState(0);
	const [transactions, setTransactions] = useState(0);
	const [locations, setLocations] = useState(0);
	const [professionals, setProfessionals] = useState(0);
	const [calculatorValue, setCalculatorValue] = useState<string>('');
  const [calculatedFee, setCalculatedFee] = useState<string>('');

	useEffect(() => {
    const fetchData = async () => {
      const result = await dashboardRequest();

			setVolume(result.response.volume);
			setTransactions(result.response.transactions);
			setLocations(result.response.locations);
			setProfessionals(result.response.professionals);
			setTopAgents(result.response.topProducing);
			setTopReffers(result.response.topReferrer);
			setNewPosts( result.response.posts);

			setHires(result.response.hires.map((item: any) => {
				return {
					name: item.fullName,
					date: '🚀 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.verifyDate)),
				};
			}));

			setDates([...result.response.birthdays.map((item: any) => {
				return {
					name: item.fullName,
					date: '🎂 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.birthDate)),
					rawDate: new Date(item.birthDate),
				};
			}),
			...result.response.anniversaries.map((item: any) => {
				return {
					name: item.fullName,
					date: '🍾 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.hireDate)),
					rawDate: new Date(item.hireDate),
				};
			})]
				.sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime())
				.slice(0, 5));
    };

    fetchData();
  }, []); 
	
	const _onCalculatorChange = (event: any) => {
		const numeric = event.target.value.replace(/[^0-9]/g, '');
		setCalculatorValue(numeric);
	
		if (numeric) {
			const resultFee = 250 + 250 * Math.ceil(parseFloat(numeric) / 200000);
			setCalculatedFee(formatAsCurrency(resultFee));
		} else {
			setCalculatedFee('');
		}
  };

	const _onTopAgentsChange = (value: string) => {
		topAgentsRequest(value)
			.then((res) => {
				setTopAgents(res.response);
			});
	};

	return (
		<Content className='p-4 flex flex-col h-[98%] overflow-auto'>
			<div className='w-full justify-evenly flex mt-[-16px] h-1/12 mb-2'>
				<DashboardStat stats={volume} name='YTD Sales Volume' money={true}></DashboardStat>
				<DashboardStat stats={transactions} name='YTD Transactions'></DashboardStat>
				<DashboardStat stats={locations} name='Locations'></DashboardStat>
				<DashboardStat stats={professionals} name='Professionals'></DashboardStat>
			</div>
			<div className='w-full h-full flex'>
				<div className='w-2/3 h-full flex'>
					<div className='w-1/2 h-full flex flex-col'>
						<Card className='flex flex-1 flex-col'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Announcements</div>
							<div className='flex-1 overflow-y-auto h-[37vh]'>
								{newPosts.map((item, index) => {
									return (<div className={`text-black rounded-xl border ${index % 2 === 0 ? 'border-distinctive-gold' : 'border-distinctive-text'} my-1 p-2`}>
										<span className={`hover:underline cursor-pointer ${index % 2 === 0 ? 'text-distinctive-gold' : 'text-distinctive-text'} font-bold`} onClick={() => navigate('/board', { state: { postId: item.id } })}>{item.title}</span>
										<div className='truncate text-gray-300 font-bold'>{item.body}</div>
									</div>);
								})}
							</div>
						</Card>
						<Card className='mt-4 min-h-[320px]'>
							<div className='w-full flex justify-between items-center'>
								<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Top Producing Agents</div>
								<Select
									className='ml-2 mt-[-20px]'
									options={[{ label: 'MTD', value: 'M'}, { label: 'QTD', value: 'Q'}, { label: 'YTD', value: 'Y'}]}
									defaultValue={'M'}
									onChange={_onTopAgentsChange}
								/>
							</div>
							{topAgents.map((item: any) => {
								return (
									<div className='flex justify-between mb-[2px]'>
										<span>{item.agent}</span>
										<span>{formatAsCurrency(item.earnings)}</span>
									</div>
								);
							})}
						</Card>
					</div>
					<div className='w-1/2 h-full flex flex-col ml-4'>
						<Card className='flex-1 flex flex-col min-h-[46vh]'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Calendar</div>
							<div className='flex-1 overflow-y-auto max-h-[36vh]'>
								<TockifyCalendar mode='calendar'/>
							</div>
						</Card>
						<Card className='mt-4 flex-grow'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Most Agents Referred (YTD)</div>
							<div className='flex-1 overflow-y-auto h-[10vh]'>
								{topReffers.map((item: any) => {
									return (
										<div className='flex justify-between mb-[2px]'>
											<span>{item.agent}</span>
											<span>{item.count + (item.count > 1 ? ' Agents' : ' Agent')}</span>
										</div>
									);
								})}
							</div>
						</Card>
						<Card className='mt-4'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Commission Calculator</div>
							<Input
								placeholder='Purchase Price'
								onChange={_onCalculatorChange}
								value={formatAsCurrency(calculatorValue)}
								className='text-center'
							/>
							<div className='w-full bg-gradient-to-r from-distinctive-light-gold to-distinctive-semi-gold h-8 mt-4 flex items-center justify-center text-black font-bold text-lg'>
								{calculatedFee}
							</div>
						</Card>
					</div>
				</div>
				<div className='ml-4 w-1/3 flex flex-col justify-between'>
					<div className='flex flex-col'>
						<Card className='h-[25vh]'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Birthdays & Anniversaries</div>
							<div className='flex-1 overflow-y-auto h-[15vh]'>
								{dates.map((item: any, idx: number) => {
									return (
										<div key={idx} className='flex justify-between mb-[2px] mr-1'>
											<span>{item.name}</span>
											<span>{item.date}</span>
										</div>
									);
								})}
							</div>
						</Card>
						<Card className='mt-4 h-[25vh]'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Welcome New Agents!</div>
							<div className='flex-1 overflow-y-auto h-[15vh]'>
								{hires.map((item: any, idx: number) => {
									return (
										<div key={idx} className='flex justify-between mb-[2px] mr-1'>
											<span>{item.name}</span>
											<span>{item.date}</span>
										</div>
									);
								})}
							</div>
						</Card>
					</div>
					<div className='flex flex-col justify-evenly mt-4'>
						<Card className='external-button-link bg-zinc-400 flex justify-end'>
							<Link to={'https://skyslope.com/'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open SkySlope</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-distinctive-gold flex justify-end mt-2'>
							<Link to={'https://lofty.com/'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Lofty</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-zinc-400 flex justify-end mt-2'>
							<Link to={'https://www.canva.com/'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Canva</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-distinctive-gold flex justify-end mt-2'>
							<Link to={'https://myhome.liveeasy.com/login'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40'>Open LiveEasy</Button>
							</Link>
						</Card>
						{isUserInAdminRole() && 
							<Card className='external-button-link bg-zinc-400 flex justify-end mt-2'>
								<Link to={'https://tockify.com/distinctionre/pinboard'} target='_blank'>
									<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Tockify</Button>
								</Link>
							</Card>
						}
					</div>
				</div>
			</div>
		</Content>
	);
};

export default Dashboard;
